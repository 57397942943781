import dayjs from "dayjs";

export type TFish = {
  id: number;
  maverickId: number;
  postId: number;
  userId: number;
  boatId: number;
  boatName: string;
  date: dayjs.Dayjs;
  isShow: number;
  isRank: number;
  rank: number;
  previousRank: number;
  name: string;
  size: number;
  jig: string;
  area: number;
  comment: string;
  images: string[];
  postStatus: string;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
};

export type TData = {
  id: null;
  userId: number;
  boatId: number;
  boatName: string;
  date: string;
  isShow: number;
  isRank: number;
  rank: number;
  previousRank: number;
  name: string;
  size: number;
  jig: string;
  area: number;
  comment: string;
  images: string;
  postStatus: string;
  createdAt: string;
  updatedAt: string;
};

export type TBoat = {
  id: number;
  name: string;
};

export const defaultBoat: TBoat = {
  id: 0,
  name: 'その他'
};