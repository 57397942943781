import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlignCenter, CenterBox, FlexBox, OmitTypography, palette } from '../../theme';
import { TFish } from '../../models/TFish';
import { pref } from '../../config';
import { FishContext } from '../../contexts/Fish';
import { AuthContext } from '../../contexts/Auth';
import { UserIcon } from '../atoms/UserIcon';
import { Asterisk } from '../../images/Asterisk';

interface OwnProps {
  post: TFish;
}

export const PostDetail: React.FC<OwnProps> = (props) => {
  const { post } = props;
  const { users, getUsers } = useContext(AuthContext);
  const { getBoat } = useContext(FishContext);
  const [boatName, setBoatName] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userIcon, setUserIcon] = useState<string>('');

  useEffect(() => {
    (async () => {
      const res = await getBoat(post.id, post.boatId);
      await getUsers();
      setBoatName(res);
    })();
    // eslint-disable-next-line
  }, [post]);

  useEffect(() => {
    const user = users.find((v) => v.id === post.maverickId);
    if (user) {
      setUserName(user.name);
      setUserIcon(user.icon);
    }
  }, [users, post]);

  return (
    <>
      <AlignCenter justifyContent="flex-start">
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
          style={{ width: 50 }}
        >
          釣 人:
        </Typography>
        <Box ml={1} />
        <FlexBox width={280}>
          <UserIcon icon={userIcon} width={20} height={20} color={palette.secondary.contrastText} />
          <Box ml={.8} />
          <OmitTypography
            variant="subtitle2"
            fontWeight={'400'}
          >
            {userName} さん
          </OmitTypography>
        </FlexBox>
      </AlignCenter>
      <Box mt={1} />
      <AlignCenter>
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
          style={{ width: 50 }}
        >
          釣 果:
        </Typography>
        <Box ml={1} />
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
        >
          {post.name}
        </Typography>
      </AlignCenter>
      <Box mt={1} />
      <AlignCenter>
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
          style={{ width: 50 }}
        >
          全 長:
        </Typography>
        <Box ml={1} />
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
        >
          <span style={{ fontWeight: 'bold' }}>{post.size}cm</span>
        </Typography>
        <Box ml={.5} />
        {post.isRank === 0 && (
          <Asterisk id="asterisk" width={15} height={15} />
        )}
      </AlignCenter>
      <Box mt={1} />
      <AlignCenter>
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
          style={{ width: 50 }}
        >
          ジ グ:
        </Typography>
        <Box ml={1} />
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
        >
          {post.jig}
        </Typography>
      </AlignCenter>
      <Box mt={1} />
      <AlignCenter>
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
          style={{ width: 50 }}
        >
          エリア:
        </Typography>
        <Box ml={1} />
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
        >
          日本{pref[post.area - 1] ? `, ${pref[post.area - 1]}` : ''}
        </Typography>
      </AlignCenter>
      <Box mt={1} />
      <AlignCenter>
        <Typography
          variant="subtitle2"
          fontWeight={'400'}
          style={{ width: 50 }}
        >
          遊漁船:
        </Typography>
        <Box ml={1} />
        {post.boatId ? (
          <CenterBox
            onClick={() => {
              const url = `${process.env.REACT_APP_URL}boat/${post.boatId}/`;
              window.open(url, '_blank');
            }}
          >
            <Typography
              color={palette.primary.light}
              fontWeight={'400'}
              fontSize={14}
              fontFamily={'"Roboto","Helvetica","Arial",sans-serif'}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
            >
              {boatName}
            </Typography>
          </CenterBox>
        ) : (
          <Typography
            variant="subtitle2"
            fontWeight={'400'}
          >
            {boatName}
          </Typography>
        )}
      </AlignCenter>
      {post.isRank === 0 && (
        <>
          <Box mt={2} />
          <AlignCenter
            justifyContent="center"
            p={.5}
            border={`2px solid #0393d6`}
            borderRadius={1.5}
          >
            <Asterisk width={16} height={16} />
            <Typography color={'#0393d6'} fontSize={16}>スケール未使用のためダービー未登録</Typography>
          </AlignCenter>
        </>
      )}
    </>
  )
}