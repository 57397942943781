import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from "@mui/material";
import { CenterColumnBox, CustomButton, palette } from '../../theme';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigateToTop } from '../../config';
import { NewsContext } from '../../contexts/News';
import { BackButton } from '../atoms/BackButton';

export const News: React.FC = () => {
  const pathName = window.location.pathname;
  const search = window.location.search;
  const navigate = useNavigateToTop();
  const { news, getNews } = useContext(NewsContext);
  const [newsId, setNewsId] = useState<number>(0);
  const [backPathName, setBackPathName] = useState<string>('');

  useEffect(() => {
    if (pathName) {
      const split = pathName.split('/');
      if (split.length === 3) {
        setNewsId(Number(split[2]));
        setBackPathName('/derby');
      } else if (split.length === 4) {
        const nIndex = search === '?path=n';
        if (nIndex) {
          setNewsId(Number(split[2]));
          setBackPathName('/news');
        } else {
          navigate('/derby');
        }
      } else {
        navigate('/derby');
      }
    }
    // eslint-disable-next-line
  }, [pathName]);

  useEffect(() => {
    (async () => {
      if (newsId) {
        await getNews(newsId);
      }
    })();
    // eslint-disable-next-line
  }, [newsId]);

  return (
    <Box>
      <BackButton pathName={backPathName} />
      <Box mt={4} />
      {!news && (
        <>
          <Box mt={5} />
          <CenterColumnBox height={200}>
            <Typography fontSize={16}>記事が見つかりませんでした</Typography>
            <Box mt={3} />
            <Box
              width={.8}
              display='flex'
              justifyContent="center"
            >
              <CustomButton
                onClick={() => {
                  navigate('/derby');
                }}
              >
                ホームへ
              </CustomButton>
            </Box>
          </CenterColumnBox>
        </>
      )}
      {news && (
        <>
          <Box>
            <Typography variant="h1">
              {news.postTitle}
            </Typography>
            <Box mt={.5} />
            <Box
              width={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <CalendarMonthIcon
                sx={{
                  color: palette.primary.contrastText,
                  width: 16,
                  height: 16
                }}
              />
              <Box mr={.3} />
              <Typography
                variant="body1"
                color={'rgb(255,255,255,.8)'}
                fontSize={13}
              >
                {news.postDate.format('YYYY年MM月DD日')}
              </Typography>
            </Box>
          </Box>
          <Box mt={3} />
          <Box>
            <Box
              color={palette.primary.contrastText}
              sx={{
                '& a': {
                  color: palette.primary.contrastText
                },
                '& img': {
                  height: 'auto',
                  maxWidth: 340,
                }
              }}
              dangerouslySetInnerHTML={{__html: news.postContent.replace(/[\n\r]/g, "<div style='margin-top: 24px;'></div>")}}
            />
          </Box>
        </>
      )}
      <Box mt={8} />
    </Box>
  )
}