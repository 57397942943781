import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigateToTop } from '../../config';
import { CenterBox, CustomButton } from '../../theme';

export const RegistrationConfirm: React.FC = () => {
  const navigate = useNavigateToTop();
  return (
    <Box>
      <Box mt={8} />
      <Box>
        <Typography fontSize={16} fontWeight={'bold'} textAlign="center">ダービーの登録情報を送信しました</Typography>
        <Box mt={1} />
        <Typography fontSize={16} fontWeight={'bold'} textAlign="center">反映されるまでしばらくお待ちください</Typography>
      </Box>
      <Box mt={8} />
      <CenterBox>
        <CustomButton
          sx={{ width: '50%' }}
          onClick={() => {
            navigate('/derby');
          }}
        >
          ホームへ
        </CustomButton>
      </CenterBox>
      <Box mt={10} />
    </Box>
  )
}
