import React, { createContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { TContext } from '../models/TContext';
import { TUser } from '../models/TUser';
import { TImage } from '../models/TImage';

interface IAuthContext {
	login: any;
	logout: any;
	user: TUser | null | undefined;
	users: TUser[];
	userLoading: boolean;
	getUsers: any;
	updateAuthUser: any;
}

export const AuthContext = createContext<IAuthContext>({
	login: undefined,
	logout: undefined,
	user: undefined,
	users: [],
	userLoading: true,
	getUsers: undefined,
	updateAuthUser: undefined
})

export const AuthProvider: React.FC<TContext> = (props: any) => {
	const [users, setUsers] = useState<TUser[]>([]);
	const [user, setUser] = useState<TUser | null | undefined>(null);
	const [userLoading, setUserLoading] = useState<boolean>(true);
	const [cookies, setCookie, removeCookie] = useCookies(['maverickCookie']);
	const cookieExpiration = '2025-03-31 23:59:59';
	const cookieDate = new Date(cookieExpiration);
	const options: CookieSetOptions = {
		expires: cookieDate,
	};

	const getUsers = async () => {
		const url = process.env.REACT_APP_URL;
		if (!url) return;
		const response = await axios({
			method: 'get',
			url: `${url}wp-json/custom/v2/maverick-users`,
		});
		const userList: TUser[] = [];
		if (response) {
			const data = response.data;
			for (let i = 0; i < data.length; i++) {
				userList.push({
					id: Number(data[i]['ID']),
					userId: Number(data[i]['user_id']),
					createdAt: dayjs(data[i]['created_at']),
					email: data[i]['email'],
					name: data[i]['name'],
					icon: data[i]['icon'],
					isEmail: Number(data[i]['is_mail']),
					orderNumber: data[i]['order_number'],
					updatedAt: dayjs(data[i]['updated_at']),
				});
			}
			setUsers([...userList]);
		}
		return userList;
	};

	useEffect(() => {
		(async () => {
      await getUsers();
    })();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const id = cookies.maverickCookie;
		if (users.length === 0) return setUserLoading(false);
		if (!id) return setUserLoading(false);
		const user = users.find((u) => u.id === id);
		if (user) {
			setUser(user);
		}
		setUserLoading(false);
	}, [users, cookies]);

	const login = (input: string) => {
		if (users.length === 0) return;
		const user = users.find((u) => u.email === input || u.orderNumber === input);
		if (user) {
			setUser(user);
			setCookie('maverickCookie', user.id, options);
			return true;
		}
		return false;
	}

	const updateAuthUser = async (files: TImage[], id: number | null, name: string, isMail: number) => {
		const url = process.env.REACT_APP_URL;
		if (!url || !user) return;
		if (id) {
			const find = users.find((v) => v.name === name && id !== v.id);
			if (find) return 999;
		} else {
			const find = users.find((v) => v.name === name);
			if (find) return 999;
		}
		const formData = new FormData();
		if (files.length > 0) {
			formData.append('fileName', files[0].url);
			formData.append('attachId', String(files[0].attachId));
		} else {
			formData.append('fileName', '');
		}
		formData.append('name', name);
		formData.append('userId', String(user.id));
		formData.append('isMail', String(isMail));
		formData.append('updatedAt', dayjs().format('YYYY-MM-DD HH:mm:ss'));
		const response = await axios({
			method: 'post',
			url: `${url}wp-json/custom/v2/maverick-update-user`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: formData
		});
		getUsers();
		return response.status;
	};

	const logout = () => {
		removeCookie('maverickCookie');
	};

	return (
		<AuthContext.Provider
			value={{
				login,
				logout,
				user,
				users,
				userLoading,
				getUsers,
				updateAuthUser
			}}
		>
			{props.children}
		</AuthContext.Provider>
	)
}