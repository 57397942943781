export const Sponsor = (props: any) => {
  const iconStyle = { width: 39, height: 12, ...props };
  return (
    <svg {...iconStyle} viewBox="0 0 39 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_158_2)">
        <path d="M5.54 8.10002L7.08 4.26002C7.53 3.13002 8.28 2.65002 9.19 2.65002H10.41C11.43 2.65002 11.68 3.19002 11.24 4.31002L9.74 8.05002C9.29 9.16002 8.56 9.80002 7.55 9.80002H6.33C5.43 9.80002 5.08 9.27002 5.55 8.11002L5.54 8.10002ZM8.14 8.35002L9.84 4.12002H8.63L6.93 8.35002H8.14Z" fill="white" />
        <path d="M10.54 8.10002L12.08 4.26002C12.53 3.13002 13.28 2.65002 14.19 2.65002H15.41C16.43 2.65002 16.68 3.19002 16.24 4.31002L14.74 8.05002C14.29 9.16002 13.56 9.80002 12.55 9.80002H11.33C10.43 9.80002 10.08 9.27002 10.55 8.11002L10.54 8.10002ZM13.14 8.35002L14.84 4.12002H13.63L11.93 8.35002H13.14Z" fill="white" />
        <path d="M20.2 0H23.26C24.37 0 24.37 0.72 23.9 1.9L21.44 8.02C21.08 8.92 20.38 9.8 19.2 9.8H16.26L20.2 0ZM19.73 8.27L22.43 1.54H21.19L18.49 8.27H19.73Z" fill="white" />
        <path d="M22.22 8.10002L23.76 4.26002C24.2 3.16002 24.96 2.65002 25.87 2.65002H27.08C28.04 2.65002 28.34 3.16002 27.89 4.26002L26.87 6.81002H24.23L23.58 8.44002H24.76L25.04 7.73002H26.5L26.35 8.11002C25.89 9.25002 25.16 9.80002 24.21 9.80002H23C22.1 9.80002 21.75 9.27002 22.22 8.11002V8.10002ZM25.33 4.07002L24.64 5.79002H25.82L26.51 4.07002H25.33Z" fill="white" />
        <path d="M31.42 1.54H29.91L30.53 0H35.14L34.52 1.54H33.04L29.73 9.8H28.1L31.41 1.54H31.42Z" fill="white" />
        <path d="M31.5 8.38003L33.23 4.07003C33.57 3.23003 34.14 2.59003 35.06 2.59003C35.68 2.59003 36.04 2.86003 36.31 3.16003L36.58 2.65003H38.04L35.17 9.79003H33.72L33.85 9.28003C33.64 9.40003 32.94 9.85003 32.14 9.85003C31.22 9.85003 31.16 9.22003 31.5 8.37003V8.38003ZM34.23 8.35003L35.93 4.12003H34.74L33.04 8.35003H34.23Z" fill="white" />
        <path d="M34.9 10.4H5.53L4.59 8.44L4.67 8.23L7.29 1.72C7.75 0.57 7.57 0 6.43 0H4.88C3.92 0 3.21 0.6 2.77 1.69L0.21 8.07C-0.26 9.23 0.0899999 9.79 0.94 9.79H3.51L4.54 11.74H34.37L34.91 10.4H34.9ZM3.05 8.27H1.72L4.43 1.54H5.76L3.06 8.27H3.05Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_158_2">
          <rect width="38.04" height="11.74" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}