import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CenterColumnBox, CustomButton, palette, StartColumnBox } from '../../theme';
import { styled } from '@mui/system';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import fishImage from '../../images/fish.jpg';
import dayjs from 'dayjs';
import { FileRejection, useDropzone } from 'react-dropzone';
import { delay, onFileDrop, pref, useNavigateToTop } from '../../config';
import { FishContext } from '../../contexts/Fish';
import { AuthContext } from '../../contexts/Auth';
import { TBoat, TData, defaultBoat } from '../../models/TFish';
import { StateContext } from '../../contexts/State';
import { TImage } from '../../models/TImage';
import { ProgressDialog } from '../dialogs/ProgressDialog';
import { BackButton } from '../atoms/BackButton';
import { CustomDatePicker } from '../atoms/CustomDatePicker';
import { CustomFormLabel } from '../atoms/CustomFormLabel';
import { ErrorTexts } from '../atoms/ErrorTexts';
import { NoRulerCheckbox } from '../atoms/NoRulerCheckbox';
import { Title } from '../atoms/Title';

const CustomTextField = styled(TextField)({
  width: '100%',
  background: 'rgb(255, 255, 255)',
  borderRadius: '5px',
  '& fieldset': {
    borderColor: 'transparent!important'
  },
  '&::placeholder': {
    color: 'rgb(0,0,0,.3)'
  }
});

export const Registration: React.FC = () => {
  const navigate = useNavigateToTop();
  const { user } = useContext(AuthContext);
  const { fishes, getBoats, insertDb, getFishesData } = useContext(FishContext);
  const { uploadImage } = useContext(StateContext);
  const [boats, setBoats] = useState<TBoat[]>([]);
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [name, setName] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [jig, setJig] = useState<string>('');
  const [sizeFiles, setSizeFiles] = useState<File[]>([]);
  const [area, setArea] = useState<string>('都道府県を検索');
  const [boat, setBoat] = useState<string>('遊漁船・船宿を検索');
  const [isBoatText, setIsBoatText] = useState<boolean>(false);
  const [boatName, setBoatName] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');
  const [sizeError, setSizeError] = useState<string>('');
  const [areaError, setAreaError] = useState<string>('');
  const [sizeFileTextError, setSizeFileTextError] = useState<string[]>([]);
  const [fileSizeText, setFileSizeText] = useState<any>(<Typography fontSize={14} fontWeight={'400'} color={palette.primary.contrastText}>ドラッグ&ドロップ、タップしてアップロード<br /><span style={{ fontWeight: 'bold' }}>10MB未満のjpg, pngのみ、画像は10枚まで。</span></Typography>);
  const [progress, setProgress] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isCheckedNoRuler, setIsCheckedNoRuler] = useState<boolean>(false);
  const [pathName, setPathName] = useState<string>('/derby');
  const pathname = window.location.pathname;
  const search = window.location.search;

  const { getRootProps: getFishRootProps, getInputProps: getFishInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => onFileDrop(acceptedFiles, fileRejections, setSizeFiles, setFileSizeText, setSizeFileTextError),
    multiple: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png']
    },
    maxSize: 1024 * 1024 * 10,
    maxFiles: 10
  });

  const handleChangeArea = async (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    if (value === '都道府県を検索' || value === '0' || !(Number(value) > 0 && Number(value) < 48)) {
      setIsBoatText(false);
      setBoatName('');
      setBoats([]);
      setArea('都道府県を検索');
      setBoat('遊漁船・船宿を検索');
      return;
    }
    const bts = await getBoats(Number(value));
    if (bts.length > 0) {
      setBoats([
        defaultBoat,
        ...bts
      ]);
    } else {
      setBoats([defaultBoat]);
    }
    setIsBoatText(false);
    setBoatName('');
    setArea(value);
    setBoat('遊漁船・船宿を検索');
    return;
  };

  const handleChangeBoat = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    if (value === '遊漁船・船宿を検索') {
      setIsBoatText(false);
      setBoatName('');
    }
    if (!value) {
      setIsBoatText(true);
    } else {
      setIsBoatText(false);
      setBoatName('');
    }
    setBoat(value);
    return;
  };

  const handleSubmit = async () => {
    setProgress(true);
    setProgressValue(10);
    if (!user) {
      setProgressValue(0);
      return setProgress(false);
    }
    if (!date || !name || !size || !sizeFiles) {
      if (!name) {
        setNameError('釣れた魚を入力してください');
      }
      if (!date) {
        setDateError('日時を入力してください');
      }
      if (!size) {
        setSizeError('個体の全長を入力してください');
      }
      if (!sizeFiles) {
        setSizeFileTextError(['全長が明確に認識できる写真を選択してください']);
      }
      setProgress(false);
      setProgressValue(0);
      return;
    }
    if (sizeFiles && Array.from(sizeFiles).length === 0) {
      if (Array.from(sizeFiles).length === 0) {
        setSizeFileTextError(['全長が明確に認識できる写真を選択してください']);
        setProgress(false);
        setProgressValue(0);
        return;
      }
    }
    setProgressValue(20);
    if (sizeFiles && Array.from(sizeFiles).length > 0) {
      try {
        setProgressValue(40);
        const uploads: TImage[] = await uploadImage(sizeFiles);
        setProgressValue(60);
        await delay(200);
        setProgressValue(70);
        await delay(500);
        if (uploads.length === sizeFiles.length) {
          setProgressValue(80);
          await delay(500);
          const filter = uploads.flatMap((v) => v.url);
          const attachIds = uploads.flatMap((v) => v.attachId);
          let str = `a:${attachIds.slice(0, 6).length}:`;
          let strAttachIds = '';
          for (let i = 0; i < attachIds.slice(0, 6).length; i++) {
            strAttachIds = strAttachIds + `i:${i};s:5:"${attachIds[i]}";`;
          }
          strAttachIds = str + '{' + strAttachIds + '}';
          setProgressValue(90);
          await delay(500);
          const isShow = fishes.length > 0 ? fishes[0].isShow : 0;
          const isRank = isCheckedNoRuler ? 0 : 1;
          const data = {
            id: null,
            maverick_id: user.id,
            userId: user.userId,
            boatId: boat === '遊漁船・船宿を検索' ? 0 : Number(boat),
            boatName: boatName,
            isShow: isShow,
            isRank: isRank,
            rank: 1,
            previousRank: 1,
            date: date!.format('YYYY-MM-DD'),
            name: name,
            size: Number.parseFloat(size),
            jig: jig,
            area: area === '都道府県を検索' ? 0 : Number(area),
            comment: comment,
            images: filter.join(),
            postStatus: 'pending',
            createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          } as TData;
          const sorted = [...fishes, data].slice().sort((a, b) => b.size - a.size);
          let rank = 1;
          let count = 0;
          let lastScore = 0;
          for (let item of sorted) {
            if (lastScore !== item.size) {
              rank = rank + count;
              count = 0;
              lastScore = item.size;
            }
            if (item.id === null) {
              item.previousRank = rank;
              item.rank = rank;
            } else {
              item.previousRank = item.rank;
              item.rank = rank;
            }
            count++;
          }
          setProgressValue(90);
          await delay(500);
          const newData = sorted.find((v) => v.id === null);
          const insert = insertDb(newData, attachIds, strAttachIds, attachIds[0]);
          if (insert) {
            await getFishesData();
            setIsUpdate(true);
            setProgressValue(95);
          }
        }
      } catch (e) {
        console.log(e)
        setProgress(false);
        alert('予期せぬエラーが発生しました');
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getFishesData();
      const list = pathname.split('/');
      if (list.length === 3) {
        const iIndex = search === '?path=i';
        if (iIndex) {
          setPathName('/info');
          return;
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (boats.length > 0) {
      setAreaError('');
    }
  }, [boats]);

  useEffect(() => {
    if (isUpdate) {
      setIsUpdate(false);
      setProgressValue(100);
    }
    // eslint-disable-next-line
  }, [isUpdate]);

  useEffect(() => {
    if (progressValue === 100) {
      navigate('/registration-confirm');
      setProgressValue(0);
    }
    // eslint-disable-next-line
  }, [progressValue]);

  return (
    <Box>
      <BackButton pathName={pathName} />
      <Title />
      <Box mt={2} />
      <Typography variant="h2">ダービー登録</Typography>
      <Box mt={3} />
      <Box>
        <StartColumnBox>
          <CustomFormLabel isRequired={true} text={'日時'} variant={'subtitle2'} />
          <Box mt={1} />
          <CustomDatePicker date={date} setDate={setDate} setDateError={setDateError} />
          {dateError && (
            <ErrorTexts errorTexts={[dateError]} />
          )}
        </StartColumnBox>
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={true} text={'釣れた魚'} variant={'subtitle2'} />
          <Box mt={1} />
          <CustomTextField
            variant="outlined"
            placeholder="カンパチ"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              style: {
                fontSize: 16,
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length > 0) {
                setNameError('');
              }
              setName(value);
            }}
          />
          {nameError && (
            <ErrorTexts errorTexts={[nameError]} />
          )}
        </StartColumnBox>
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={true} text={'個体の全長(cm)'} variant={'subtitle2'} />
          <Box mt={1} />
          <Box
            width={1}
            display="flex"
            alignItems="flex-end"
          >
            <CustomTextField
              type="number"
              variant="outlined"
              placeholder="60"
              value={size}
              inputProps={{ pattern: '^[0-9]+$' }}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                style: {
                  fontSize: 16
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 0) {
                  setSizeError('');
                }
                if (Math.round(Number(value)) === 0) {
                  setSize('');
                } else {
                  setSize(String(Math.round(Number(value))));
                }
              }}
            />
            <Box ml={.5} />
            <Typography
              fontSize={18}
              fontWeight={'bold'}
            >
              cm
            </Typography>
          </Box>
          <Box mt={.5} />
          <Typography fontSize={14}>※ 1センチ以下は切り捨てで表記</Typography>
          {sizeError && (
            <ErrorTexts errorTexts={[sizeError]} />
          )}
        </StartColumnBox>
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={false} text={'使用ジグ'} variant={'subtitle2'} />
          <Box mt={1} />
          <CustomTextField
            variant="outlined"
            placeholder="マーベリック アカキン"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              style: {
                fontSize: 16
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              setJig(value);
            }}
          />
        </StartColumnBox>
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={false} text={'エリア'} variant={'subtitle2'} />
          <Box mt={1} />
          <Select
            value={area}
            onChange={handleChangeArea}
            sx={{
              width: '100%',
              background: "rgb(255, 255, 255)",
              fontSize: 16
            }}
          >
            <MenuItem value={'都道府県を検索'} selected>都道府県を検索</MenuItem>
            {pref.flatMap((v, idx) => {
              return (
                <MenuItem key={idx + 1} value={idx + 1}>{v}</MenuItem>
              );
            })}
          </Select>
        </StartColumnBox>
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={false} text={'遊漁船・船宿'} variant={'subtitle2'} />
          <Box mt={1} />
          <Select
            disabled={boats.length === 0}
            value={boat}
            onClick={() => {
              if (boats.length === 0) {
                setAreaError('都道府県を選択してください');
              }
            }}
            onChange={handleChangeBoat}
            sx={{
              width: '100%',
              background: 'rgb(255, 255, 255)',
              fontSize: 16
            }}
          >
            <MenuItem value={'遊漁船・船宿を検索'} selected>遊漁船・船宿を検索</MenuItem>
            {boats.flatMap((boat, idx) => {
              return (
                <MenuItem key={idx + 1} value={boat.id}>{boat.name}</MenuItem>
              );
            })}
          </Select>
          <Box mt={1} />
          <Typography color={palette.error.main} fontSize={15}>{areaError}</Typography>
        </StartColumnBox>
        {isBoatText && (
          <>
            <Box mt={1} />
            <StartColumnBox>
              <CustomFormLabel isRequired={false} text={'遊漁船・船宿を入力'} variant={'subtitle2'} />
              <Box mt={1} />
              <CustomTextField
                variant="outlined"
                placeholder="マーベリック丸"
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  style: {
                    fontSize: 16,
                  }
                }}
                onChange={(e) => { setBoatName(e.target.value); }}
              />
            </StartColumnBox>
          </>
        )}
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={false} text={'コメント'} variant={'subtitle2'} />
          <Box mt={1} />
          <CustomTextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="活動メモを入力"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              style: {
                fontSize: 16
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              setComment(value);
            }}
          />
        </StartColumnBox>
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={true} text={'全長が明確に認識できる写真'} variant={'subtitle2'} />
          <Box mt={.5} />
          <NoRulerCheckbox isCheckedNoRuler={isCheckedNoRuler} setIsCheckedNoRuler={setIsCheckedNoRuler} />
          <Box mt={1} />
          <CenterColumnBox
            {...getFishRootProps()}
            width={1}
            bgcolor={'#f2f2f2'}
            borderRadius={1}
            justifyContent="center"
            sx={{ cursor: 'pointer' }}
          >
            <Box mt={3} />
            <Typography
              variant="h1"
              color={palette.secondary.contrastText}
            >
              メジャーを使用してください。
            </Typography>
            <Box
              component={'img'}
              alt={''}
              src={fishImage}
              width={280}
            />
            <Typography
              variant="h2"
              color={palette.secondary.contrastText}
              fontSize={18}
            >
              尾びれ〜口先までの全長
            </Typography>
            <Box mt={1} />
            <Typography
              variant="subtitle1"
              fontSize={10}
              color={palette.secondary.contrastText}
              sx={{ textDecoration: 'underline' }}
            >
              ※尾びれを挟める行為はNGです。開いた状態で撮影してください。
            </Typography>
            <Box mt={3} />
            <CenterColumnBox
              width={295}
              bgcolor={palette.secondary.light}
              borderRadius={1.5}
              justifyContent="center"
              sx={{ padding: '8px 16px' }}
            >
              <FileUploadIcon sx={{ color: palette.primary.contrastText }} />
              <Box
                width={1}
                mt={1}
                mb={1}
              >
                {fileSizeText}
              </Box>
              <input {...getFishInputProps()} />
            </CenterColumnBox>
            <Box mt={2} />
          </CenterColumnBox>
          {sizeFileTextError && sizeFileTextError.length > 0 && (
            <ErrorTexts errorTexts={sizeFileTextError} />
          )}
        </StartColumnBox>
        <Box mt={4} />
        <CustomButton
          variant="contained"
          disabled={progress}
          sx={{
            '&:disabled': {
              background: palette.secondary.light,
              color: 'rgb(255,255,255,.6)'
            }
          }}
          onClick={() => {
            handleSubmit();
          }}
        >
          投稿する
        </CustomButton>
      </Box>
      <Box mt={8} />
      <ProgressDialog open={progress} text={'登録情報をアップロード中...'} value={progressValue} />
    </Box>
  )
}