import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box width={1} display="flex" alignItems="center">
      <Box width={1} mr={1}>
        <LinearProgress sx={{ height: 10, borderRadius: 2.5 }} variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="subtitle1"
          fontSize={15}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}