import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { TImage } from '../models/TImage';
import { AuthContext } from './Auth';

interface IStateContext {
  loading: boolean;
  isComment: string;
  isHamburgerOpen: boolean;
  isLoadingAllNews: boolean;
  isLoadingPosts: boolean;
  setLoading: any;
  setIsLoadingAllNews: any;
  setIsLoadingPosts: any;
  setIsHamburgerOpen: any;
  setIsComment: any;
  uploadImage: any;
}

const StateContext = createContext<IStateContext>({
  loading: true,
  isComment: '',
  isHamburgerOpen: false,
  setLoading: undefined,
  isLoadingAllNews: false,
  isLoadingPosts: false,
  setIsHamburgerOpen: undefined,
  setIsLoadingAllNews: undefined,
  setIsLoadingPosts: undefined,
  setIsComment: undefined,
  uploadImage: undefined
});

const StateProvider = (props: any) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
  const [isLoadingPosts, setIsLoadingPosts] = useState<boolean>(false);
  const [isLoadingAllNews, setIsLoadingAllNews] = useState<boolean>(false);
  const [isComment, setIsComment] = useState<string>('');

  const uploadImage = async (files: FileList) => {
    const url = process.env.REACT_APP_URL;
    if (!url) return;
    if (files.length === 0) return;
    const fileList = Array.from(files);
    const uploadImages: TImage[] = [];
    for (let v of fileList) {
      const formData = new FormData();
      formData.append('file', v);
      formData.append('userId', user ? String(user.userId) : '0');
      const response = await axios({
        method: 'post',
        url: `${url}wp-json/custom/v2/maverick-upload-image`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData
      });
      const imageUrl = {
        url: String(response.data.req.data.moveFile.url),
        attachId: Number(response.data.req.data.attachId),
        attachFile: response.data.req.data.attachData
      };
      uploadImages.push(imageUrl);
    }
    return uploadImages;
  };

  return (
    <StateContext.Provider
      value={{
        loading,
        isComment,
        isHamburgerOpen,
        isLoadingAllNews,
        isLoadingPosts,
        setLoading,
        setIsHamburgerOpen,
        setIsLoadingAllNews,
        setIsLoadingPosts,
        setIsComment,
        uploadImage
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };