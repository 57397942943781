import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Logo } from '../../images/Logo';
import { AlignCenter, CenterBox, CenterColumnBox, palette } from '../../theme';
import { StateContext } from '../../contexts/State';
import { Sponsor } from '../../images/Sponsor';
import Hamburger from 'hamburger-react';
import { useNavigateToTop } from '../../config';
import { AuthContext } from '../../contexts/Auth';

export const SideMenu: React.FC = () => {
  const { isHamburgerOpen, setIsHamburgerOpen } = useContext(StateContext);
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigateToTop();

  const handleClick = () => {
    const url = "https://qoodeta098.studio.site/";
    window.open(url, '_blank');
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
      >
        <AlignCenter
          width={340}
          justifyContent="space-between"
          bgcolor={palette.background.default}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setIsHamburgerOpen(false);
              navigate('/derby');
            }}
          >
            <Logo width={105} />
          </Box>
          <Hamburger
            label="Show menu"
            color={palette.background.paper}
            toggled={isHamburgerOpen}
            toggle={setIsHamburgerOpen}
          />
        </AlignCenter>
      </Box>
      <CenterBox style={{ overflow: 'auto' }}>
        <List style={{ width: 340 }}>
          <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
          <ListItem
            onClick={() => {
              setIsHamburgerOpen(false);
              navigate('/derby');
            }}
            style={{
              color: 'white',
              cursor: 'pointer',
              margin: '5px 0'
            }}
          >
            <ListItemText
              primary={'ホーム'}
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            />
          </ListItem>
          <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
          <ListItem
            onClick={() => {
              setIsHamburgerOpen(false);
              navigate('/info');
            }}
            style={{
              color: 'white',
              cursor: 'pointer',
              margin: '5px 0'
            }}
          >
            <ListItemText
              primary={'ダービーについて'}
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            />
          </ListItem>
          <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
          <ListItem
            onClick={() => {
              const url = 'https://maverickokou.theshop.jp/';
              window.open(url, '_blank');
            }}
            style={{
              color: 'white',
              cursor: 'pointer',
              margin: '5px 0'
            }}
          >
            <ListItemText
              primary={'販売ページ'}
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            />
          </ListItem>
          <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
          {user && user.name && (
            <>
              <ListItem
                onClick={() => {
                  setIsHamburgerOpen(false);
                  navigate('/mypage');
                }}
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  margin: '5px 0'
                }}
              >
                <ListItemText
                  primary={'マイページ'}
                  primaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                />
              </ListItem>
              <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
            </>
          )}
          {user && user.name && (
            <>
              <ListItem
                onClick={() => {
                  logout();
                  window.location.href="/login"
                }}
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  margin: '5px 0'
                }}
              >
                <ListItemText
                  primary={'ログアウト'}
                  primaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                />
              </ListItem>
              <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
            </>
          )}
        </List>
      </CenterBox>
      <CenterColumnBox
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        margin={'auto'}
        mb={3}
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Typography
          fontSize={10}
          fontWeight={'bold'}
        >
          PRODUCED BY
        </Typography>
        <Box height={1}>
          <Sponsor width={70} height={30} />
        </Box>
        <Box mt={2} />
      </CenterColumnBox>
    </>
  );
}