import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { AlignCenter, CenterBox, CenterColumnBox, CustomButton, CustomImageBox, OmitTypography, RankingNumber, palette } from '../../theme';
import { rankingColors, rankingIcon, rankingTableRow, useNavigateToTop } from '../../config';
import { FishContext } from '../../contexts/Fish';
import { AuthContext } from '../../contexts/Auth';
import { StateContext } from '../../contexts/State';
import { NewsContext } from '../../contexts/News';
import { Title } from '../atoms/Title';
import { UserIcon } from '../atoms/UserIcon';
import { RankingTable } from '../organisms/RankingTable';
import { TData as TRankingData } from '../../models/TRanking';
import { Sponsor } from '../../images/Sponsor';
import { NewsComponent } from '../molecules/NewsComponent';
import { TailSpin } from 'react-loader-spinner';
import { TFish } from '../../models/TFish';
import { TUser } from '../../models/TUser';

export const Ranking: React.FC = () => {
  const navigate = useNavigateToTop();
  const { fishes, getFishesData, getBoat } = useContext(FishContext);
  const { users, getUsers } = useContext(AuthContext);
  const { isLoadingAllNews, isLoadingPosts } = useContext(StateContext);
  const { allNews, getNews, getAllNews } = useContext(NewsContext);
  const [isNewsMenu, setIsNewsMenu] = useState<boolean>(false);
  const [isRowTables, setIsRowTables] = useState<boolean>(false);
  const [isShowTables, setIsShowTables] = useState<boolean>(false);
  const [isLoadingItem, setIsLoadingItem] = useState<boolean>(false);
  const [rowTables, setRowTables] = useState<TRankingData[]>([]);

  useEffect(() => {
    (async () => {
      const userData: TUser[] = await getUsers();
      await getAllNews();
      const fishesData: TFish[] = await getFishesData();
      if (userData.length === 0 && fishesData.length === 0) {
        setIsLoadingItem(true);
      } else if (fishesData.length === 0) {
        setIsLoadingItem(true);
      } else if (userData.length > 0 && fishesData.length > 0) {
        const isShow = fishesData[0].isShow;
        setIsLoadingItem(true);
        setIsShowTables(isShow === 1);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (users.length > 0 && fishes.length > 0) {
        const sortedRowsTable = fishes.slice(4, fishes.length);
        const rows = await rankingTableRow(getBoat, sortedRowsTable, users);
        setRowTables(rows);
        setIsRowTables(true);
      }
    })();
    // eslint-disable-next-line
  }, [fishes, users]);

  const handleClick = () => {
    const url = "https://qoodeta098.studio.site/";
    window.open(url, '_blank');
  };

  return (
    <Box>
      <NewsComponent
        isLoadingAllNews={isLoadingAllNews}
        isNewsMenu={isNewsMenu}
        setIsNewsMenu={setIsNewsMenu}
        allNews={allNews}
        getNews={getNews}
      />
      <Box mt={3} />
      {isLoadingPosts && !isLoadingItem && (
        <CenterBox height={200}>
          <TailSpin
            color={palette.background.paper}
            height={100}
            width={100}
          />
        </CenterBox>
      )}
      {isShowTables && (
        <>
          <Title />
          <Box mt={2} />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {fishes.slice(0, 4).flatMap((fish, idx) => {
              const rankIcon = rankingIcon(fish);
              const rankingColor = rankingColors.find((rankingColor) => rankingColor.label === fish.rank);
              const user = users.find((v) => v.id === fish.maverickId);
              let iconEl = rankIcon ? rankIcon.icon : <Box />;
              if (!fish.isRank) {
                iconEl = <Typography/>;
              }
              return (
                <Grid
                  key={idx}
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => { navigate(`/detail/${fish.id}/?path=d`); }}
                >
                  <CenterColumnBox
                    justifyContent="center"
                    width={160}
                    height={1}
                  >
                    <Box
                      position="relative"
                      width={160}
                      height={160}
                    >
                      <CustomImageBox
                        style={{
                          backgroundImage: `url(${fish.images[0]})`,
                        }}
                      />
                      <Box
                        position="absolute"
                        bottom={0}
                        width={1}
                        bgcolor={'rgb(40,47,55,.8)'}
                      >
                        <AlignCenter
                          p={.5}
                          justifyContent="space-between"
                        >
                          {fish.isShow === 1 && (
                            <AlignCenter>
                              {fish.isRank === 1 && (
                                <RankingNumber bgcolor={rankingColor ? rankingColor.colorCode : palette.background.paper}>
                                  {fish.rank}
                                </RankingNumber>
                              )}
                              <Box ml={.3} />
                              {iconEl}
                            </AlignCenter>
                          )}
                          <Box ml={1} />
                          <Typography variant="body1">{fish.name} {fish.size}cm</Typography>
                        </AlignCenter>
                      </Box>
                    </Box>
                    <AlignCenter
                      pt={.5}
                      pb={.5}
                      width={160}
                      justifyContent="space-between"
                    >
                      <Box
                        pl={.5}
                        width={90}
                        display="flex"
                        alignItems="flex-end"
                      >
                        <UserIcon icon={user ? user.icon : ''} width={15} height={15} color={palette.secondary.contrastText} />
                        <Box ml={.5} />
                        <Box width={30}>
                          <OmitTypography
                            variant="body1"
                            lineHeight={1.4}
                          >
                            {user ? user.name : 'user'}
                          </OmitTypography>
                        </Box>
                        <Typography
                          variant="body2"
                          lineHeight={1.5}
                        >
                          さん
                        </Typography>
                      </Box>
                      <Box pr={.5}>
                        <Typography variant="body1">{fish.date.format('YYYY.MM.DD')}</Typography>
                      </Box>
                    </AlignCenter>
                  </CenterColumnBox>
                </Grid>
              );
            })}
          </Grid>
          {fishes.length > 4 ? (
            <>
              <Box mt={4} />
              <RankingTable posts={fishes.slice(4, fishes.length)} isRowTables={isRowTables} rowTables={rowTables} />
              <Box mt={4} />
            </>
          ) : (
            <Box mt={4} />
          )}
        </>
      )}
      <CenterBox
        position="fixed"
        bottom={0}
        right={0}
        left={0}
        m={'auto'}
        zIndex={100}
        height={104}
        bgcolor={'#222222'}
      >
        <CustomButton
          variant="contained"
          sx={{
            width: 340,
            minHeight: 60,
            fontSize: 20,
            display: 'flex',
            flexDirection: 'column'
          }}
          onClick={() => {
            navigate('/registration');
          }}
        >
          <span style={{ fontSize: 11 }}>【ダービーエントリーはこちらから】</span>
          投稿する
        </CustomButton>
      </CenterBox>
      <Box mt={8} />
      <CenterColumnBox
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Typography
          fontSize={10}
          fontWeight={'bold'}
        >
          PRODUCED BY
        </Typography>
        <Box height={1}>
          <Sponsor width={70} height={30} />
        </Box>
        <Box mt={2} />
      </CenterColumnBox>
      <Box mt={8} />
    </Box>
  )
}