import React from 'react';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { CenterBox, palette } from '../../theme';

interface OwnProps {
  color: string;
  icon: string;
  width: number;
  height: number;
}

export const UserIcon: React.FC<OwnProps> = (props) => {
  const { color, icon, width, height } = props;
  return (
    <CenterBox
      height={height}
      width={width}
      borderRadius={10}
      bgcolor={palette.primary.contrastText}
    >
      {icon ? (
        <Avatar
          alt="アイコン"
          src={icon}
          sx={{
            width: width,
            height: height
          }}
        />
      ) : (
        <PersonIcon
          style={{
            color: color,
            width: width,
            height: height
          }}
        />
      )}
    </CenterBox>
  )
}
