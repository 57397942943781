import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { useWindowSize } from 'react-use';
import { CenterColumnBox, palette, theme } from '../../theme';
import { useNavigateToTop } from '../../config';

const CustomMainText = styled(Typography)({
  fontSize: 100,
  [theme.breakpoints.down('sm')]: {
    fontSize: 80
  }
});

const CustomSubText = styled(Typography)({
  fontSize: 40,
  [theme.breakpoints.down('sm')]: {
    fontSize: 35
  }
});

const CustomSubText2 = styled(Typography)({
  fontSize: 20,
  [theme.breakpoints.down('sm')]: {
    fontSize: 15
  }
});

export const NotFound: React.FC = () => {
  const { height } = useWindowSize();
  const navigate = useNavigateToTop();

  useEffect(() => {
    const currentPathName = window.location.pathname;
    setTimeout(() => {
      const newPathName = window.location.pathname;
      if (currentPathName === newPathName) {
        navigate('/derby');
      }
    }, 10000);
    // eslint-disable-next-line
  }, []);

  return (
    <CenterColumnBox
      height={height}
      width={1}
      justifyContent="center"
      bgcolor={palette.secondary.contrastText}
    >
      <CustomMainText variant="h1">404</CustomMainText>
      <CustomSubText variant="h1">Page Not Found</CustomSubText>
      <Box mt={1} />
      <CustomSubText2 variant="h1">お探しのページは見つかりませんでした。</CustomSubText2>
      <Box mt={3} />
    </CenterColumnBox>
  )
}