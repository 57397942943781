import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { palette } from '../../theme';

interface OwnProps {
  isCheckedNoRuler: boolean;
  setIsCheckedNoRuler: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoRulerCheckbox: React.FC<OwnProps> = (props) => {
  const { isCheckedNoRuler, setIsCheckedNoRuler } = props;
  return (
    <>
      <FormGroup>
        <FormControlLabel
          onChange={() => { setIsCheckedNoRuler(!isCheckedNoRuler) }}
          control={
            <Checkbox
              style={{
                color: palette.primary.contrastText,
                padding: 4,
                paddingLeft: 8
              }}
            />
          }
          label={
            <Typography variant="subtitle2">スケール・ものさしを使っていない</Typography>}
        />
      </FormGroup>
      {
        isCheckedNoRuler && (
          <Typography
            color={'#ee7800'}
            fontSize={13}
          >
            ※ ダービーランキングには反映されませんが、釣果を共有することができます。
          </Typography>
        )
      }
    </>
  )
}