import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { TNews } from '../models/TNews';
import dayjs from 'dayjs';
import { StateContext } from './State';

interface INewsContext {
  news: TNews | undefined;
  setNews: any;
  allNews: TNews[];
  getNews: any;
  getAllNews: any;
}

const NewsContext = createContext<INewsContext>({
  news: undefined,
  setNews: undefined,
  allNews: [],
  getNews: undefined,
  getAllNews: undefined,
});

const NewsProvider = (props: any) => {
  const { setIsLoadingAllNews } = useContext(StateContext);
  const [news, setNews] = useState<TNews | undefined>();
  const [allNews, setAllNews] = useState<TNews[]>([]);

  const getNews = async (newsId: number) => {
    const url = process.env.REACT_APP_URL;
    if (!url) return;
    const response = await axios({
      method: 'get',
      url: `${url}wp-json/custom/v2/maverick-get-news/${newsId}`,
    });
    const req = response.data.req.data;
    const data = {
      ID: req['ID'],
      commentCount: req['comment_count'],
      commentStatus: req['comment_status'],
      filter: req['filter'],
      guid: req['guid'],
      menuOrder: req['menu_order'],
      pingStatus: req['ping_status'],
      pinged: req['pinged'],
      postAuthor: req['post_author'],
      postContent: req['post_content'],
      postContentFiltered: req['post_content_filtered'],
      postDate: dayjs(req['post_date']),
      postDateGmt: dayjs(req['post_date_gmt']),
      postExcerpt: req['post_excerpt'],
      postMimeType: req['post_mime_type'],
      postModified: dayjs(req['post_modified']),
      postModifiedGmt: dayjs(req['post_modified_gmt']),
      postName: req['post_name'],
      postParent: req['post_parent'],
      postPassword: req['post_password'],
      postStatus: req['post_status'],
      postTitle: req['post_title'],
      postType: req['post_type'],
      toPing: req['to_ping'],
    } as TNews;
    setNews(data);
    return response.data;
  };

  const getAllNews = async () => {
    const url = process.env.REACT_APP_URL;
    if (!url) return;
    const response = await axios({
      method: 'get',
      url: `${url}wp-json/custom/v2/maverick-get-all-news`,
    });
    const list = [];
    const req = response.data.req.data;
    for (let i = 0; i < req.length; i++) {
      const data = {
        ID: req[i]['ID'],
        commentCount: req[i]['comment_count'],
        commentStatus: req[i]['comment_status'],
        filter: req[i]['filter'],
        guid: req[i]['guid'],
        menuOrder: req[i]['menu_order'],
        pingStatus: req[i]['ping_status'],
        pinged: req[i]['pinged'],
        postAuthor: req[i]['post_author'],
        postContent: req[i]['post_content'],
        postContentFiltered: req[i]['post_content_filtered'],
        postDate: dayjs(req[i]['post_date']),
        postDateGmt: dayjs(req[i]['post_date_gmt']),
        postExcerpt: req[i]['post_excerpt'],
        postMimeType: req[i]['post_mime_type'],
        postModified: dayjs(req[i]['post_modified']),
        postModifiedGmt: dayjs(req[i]['post_modified_gmt']),
        postName: req[i]['post_name'],
        postParent: req[i]['post_parent'],
        postPassword: req[i]['post_password'],
        postStatus: req[i]['post_status'],
        postTitle: req[i]['post_title'],
        postType: req[i]['post_type'],
        toPing: req[i]['to_ping'],
      } as TNews;
      list.push(data);
    }
    const sorted = [...list].sort((a, b) => b.ID - a.ID);
    if (sorted.length > 0) setIsLoadingAllNews(true);
    setAllNews(sorted);
    return response.data;
  };

  return (
    <NewsContext.Provider
      value={{
        news,
        setNews,
        allNews,
        getNews,
        getAllNews
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
};

export { NewsContext, NewsProvider };