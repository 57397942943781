import { Box, Typography } from '@mui/material';
import React from 'react';
import { palette } from '../../theme';

interface OwnProps {
  errorTexts: string[];
}

export const ErrorTexts: React.FC<OwnProps> = (props) => {
  const { errorTexts } = props;
  return (
    <>
      <Box mt={1} />
      {errorTexts.flatMap((error, idx) => {
        return (
          <Typography key={idx} color={palette.error.main} fontSize={15}>{error}</Typography>
        );
      })}
    </>
  )
}