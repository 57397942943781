import React from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface OwnProps {
  date: dayjs.Dayjs | null;
  setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  setDateError: React.Dispatch<React.SetStateAction<string>>;
}

const DatePickerComponent = styled(DatePicker)({
  background: 'rgb(255, 255, 255)',
  width: '100%',
  borderRadius: '5px',
  '& fieldset': {
    borderColor: 'transparent!important'
  },
  '& input': {
    fontSize: 15
  },
  '&::placeholder': {
    color: 'rgb(0,0,0,.3)'
  },
  '& div.MuiPickersCalendarHeader-labelContainer': {
    fontSize: 15,
  }
});

export const CustomDatePicker: React.FC<OwnProps> = (props) => {
  const { date, setDate, setDateError } = props;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ja"
    >
      <DatePickerComponent
        format={'YYYY年MM月DD日'}
        slotProps={{
          toolbar: { toolbarFormat: 'YYYY年MM月DD日', hidden: false },
          calendarHeader: { format: 'YYYY年 MM月' }
        }}
        value={date}
        onChange={(value) => {
          if (value) {
            setDateError('');
          }
          setDate(value);
        }}
      />
    </LocalizationProvider>
  )
}