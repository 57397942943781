import { Box, Typography } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/system';
import { CenterBox, CenterColumnBox, CustomButton, CustomTextField, StartColumnBox, palette, theme } from '../../theme';
import { useWindowSize } from 'react-use';
import { AuthContext } from '../../contexts/Auth';
import { Logo } from '../../images/Logo';
import { Sponsor } from '../../images/Sponsor';
import { ErrorTexts } from '../atoms/ErrorTexts';
import { useNavigateToTop } from '../../config';
import movie from '../../images/login.mp4';

export const Login: React.FC = () => {
  const { height, width } = useWindowSize();
  const navigate = useNavigateToTop();
  const { login } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState<string>('');
  const [inputErrorText, setInputErrorText] = useState<string>('');
  const [inputError, setInputError] = useState<boolean>(false);

  const handleClick = () => {
    if (!input) {
      setInputErrorText('注文番号orメールアドレスを入力してください');
      setInputError(true);
      return;
    }
    const check = login(input);
    if (!check) {
      setInputError(true);
      setInputErrorText('注文番号orメールアドレスが間違っています');
    }
    return;
  }

  const handleClickLogo = () => {
    const url = "https://qoodeta098.studio.site/";
    window.open(url, '_blank');
  };

  return (
    <CenterColumnBox
      minHeight={height}
      width={1}
      bgcolor={palette.background.default}
      justifyContent="flex-start"
      position='relative'
    >
      <Box
        position="relative"
        width={isSmallScreen ? width : 340}
        height={height}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <video
          playsInline
          autoPlay
          loop
          muted
          style={{
            filter: 'brightness(0.3)',
            width: isSmallScreen ? width : 340,
            maxWidth: isSmallScreen ? width : 340,
            position: 'absolute',
            zIndex: 0
          }}
        >
          <source src={movie} type="video/mp4" />
        </video>
        <Box
          position="relative"
          zIndex={10}
          pt={8}
        >
          <CenterColumnBox>
            <Box height={1}>
              <Logo width={180} />
            </Box>
            <Box mt={2} />
            <Typography variant="h2">購入者限定 マーベリックダービーへのご招待</Typography>
          </CenterColumnBox>
          <Box mt={7} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              bgcolor={'#f1f1f1'}
              padding={2}
              paddingTop={5}
              borderRadius={'8px'}
              width={isSmallScreen ? width - 48 : 290}
            >
              <StartColumnBox>
                <Typography variant="subtitle1">注文番号 or メールアドレスを入力</Typography>
                <Box mt={1} />
                <CustomTextField
                  error={inputError}
                  inputProps={{ pattern: '^[a-zA-Z0-9@._]+$' }}
                  inputRef={inputRef}
                  variant="outlined"
                  placeholder="注文番号 or メールアドレス"
                  InputLabelProps={{ shrink: false }}
                  helperText={inputRef?.current?.validationMessage}
                  InputProps={{
                    style: {
                      fontSize: 16,
                      borderRadius: '8px'
                    }
                  }}
                  sx={{
                    '& .Mui-error.MuiFormLabel-root': {
                      color: palette.error.main,
                    },
                    '& .MuiFormHelperText-contained': {
                      color: palette.error.main,
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 0) {
                      setInputError(false);
                      setInputErrorText('');
                    }
                    setInput(value);
                  }}
                />
                {inputErrorText && (
                  <ErrorTexts errorTexts={[inputErrorText]} />
                )}
              </StartColumnBox>
              <Box mt={3} />
              <CustomButton
                variant="contained"
                onClick={handleClick}
              >
                GO!
              </CustomButton>
            </Box>
          </Box>
          <Box mt={2} />
          <CenterBox onClick={() => { navigate('/derby'); }}>
            <Typography
              color={palette.primary.contrastText}
              fontWeight={'bold'}
              fontSize={14}
              fontFamily={'"Roboto","Helvetica","Arial",sans-serif'}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
            >
              ランキング一覧へ
            </Typography>
          </CenterBox>
          <Box mt={7} />
          <CenterColumnBox
            sx={{ cursor: 'pointer' }}
            onClick={handleClickLogo}
          >
            <Typography
              fontSize={9}
              fontWeight={'bold'}
            >
              PRODUCED BY
            </Typography>
            <Sponsor width={70} height={30} />
          </CenterColumnBox>
          <Box pt={5} />
        </Box>
      </Box>
    </CenterColumnBox>
  )
}