import React from 'react';
import { CenterColumnBox } from '../../theme';
import { Box, Typography } from '@mui/material';
import { Sponsor } from '../../images/Sponsor';

export const Footer: React.FC = () => {
  const handleClick = () => {
    const url = "https://qoodeta098.studio.site/";
    window.open(url, '_blank');
  };
  return (
    <CenterColumnBox
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <Typography
        fontSize={10}
        fontWeight={'bold'}
      >
        PRODUCED BY
      </Typography>
      <Box height={1}>
        <Sponsor width={70} height={30} />
      </Box>
      <Box mt={2}/>
    </CenterColumnBox>
  )
}