import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { FileRejection } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import { CenterBox, OmitTypography, palette } from './theme';
import { TData, TRanking } from './models/TRanking';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TFish } from './models/TFish';
import { TUser } from './models/TUser';
import { UserIcon } from './components/atoms/UserIcon';

export const rankingIcons = [
  {
    label: 'stay',
    icon: <EastIcon style={{ color: '#ffc403', width: 18 }} />
  },
  {
    label: 'up',
    icon: <NorthIcon style={{ color: '#ff0200', width: 18 }} />
  },
  {
    label: 'down',
    icon: <SouthIcon style={{ color: '#1E90FF', width: 18 }} />
  }
];

export const rankingColors = [
  {
    label: 1,
    colorCode: '#dec928'
  },
  {
    label: 2,
    colorCode: '#a8a8a8'
  },
  {
    label: 3,
    colorCode: '#af825d'
  },
  {
    label: 4,
    colorCode: '#717171'
  }
];

export const pref = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県",
  "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県",
  "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県",
  "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県",
  "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県",
  "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県",
  "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

export const delay = (ms: number): Promise<unknown> => {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms)
  });
};

export const useNavigateToTop = () => {
  const navigate = useNavigate();

  const navigateAndReset = (to: string) => {
    navigate(to, { replace: true });
    window.scrollTo(0, 0);
  };

  return navigateAndReset;
};

export const rankingIcon = (post: TFish) => {
  let rankingIcon = rankingIcons.find((icon) => icon.label === 'stay');
  if (post.rank > post.previousRank) {
    rankingIcon = rankingIcons.find((icon) => icon.label === 'down');
  } else if (post.rank < post.previousRank) {
    rankingIcon = rankingIcons.find((icon) => icon.label === 'up');
  }
  return rankingIcon;
};

export const rankingTableRow = async (getBoat: any, posts: TFish[], users: TUser[]) => {
  const blocks: TRanking[] = [];
  for (let post of posts) {
    const rankIcon = rankingIcon(post);
    const user = users.find((v) => v.id === post.maverickId);
    const boatName = await getBoat(post.id, post.boatId);
    blocks.push([
      String(post.rank),
      rankIcon ? rankIcon.icon : <Box />,
      <CenterBox>
        <UserIcon icon={user ? user.icon : ''} width={15} height={15} color={palette.secondary.contrastText} />
        <Box ml={.5} />
        <OmitTypography fontSize={12}>{user ? user.name : 'user'}</OmitTypography>
      </CenterBox>,
      <Typography fontSize={12}><span style={{ fontSize: 15, fontWeight: 'bold' }}>{post.size}</span>cm</Typography>,
      `${post.createdAt.format('M/D')}`,
      <OmitTypography fontSize={12}>{boatName}</OmitTypography>,
      <ArrowForwardIosIcon style={{ width: 15 }} />,
      String(post.isRank),
    ]);
  }

  const createData = (
    rank: string,
    rankIcon: any,
    user: any,
    size: string,
    date: string,
    boatName: any,
    direction: any,
    isRank: boolean
  ): TData => {
    return { rank, rankIcon, user, size, date, boatName, direction, isRank };
  }

  const rows: TData[] = Array.from({ length: posts.length }, (_, index) => {
    const randomSelection = blocks[index];
    return createData(...randomSelection);
  });
  return rows;
};

export const onUserFileDrop = (
  acceptedFiles: File[],
  fileRejections: FileRejection[],
  setFiles: React.Dispatch<React.SetStateAction<File[]>>,
  setFileText: React.Dispatch<any>,
  setTextError: React.Dispatch<React.SetStateAction<string[]>>,
  setImage: React.Dispatch<React.SetStateAction<string>>
) => {
  if (fileRejections.length > 0) {
    const errors = userRejections(fileRejections);
    if (errors.length > 0) {
      setTextError(errors);
      setFileText(<Typography fontSize={14} fontWeight={'400'} color={palette.secondary.contrastText}>ドラッグ&ドロップ、タップしてアップロード<br /><span style={{ fontWeight: 'bold' }}>10MB未満のjpg, png</span></Typography>);
      setFiles([]);
      setImage('');
      return;
    }
  }
  const files = acceptedFiles;
  if (!files || files.length === 0) {
    setFileText(<Typography fontSize={14} fontWeight={'400'} color={palette.secondary.contrastText}>ドラッグ&ドロップ、タップしてアップロード<br /><span style={{ fontWeight: 'bold' }}>10MB未満のjpg, png</span></Typography>);
    setFiles([]);
    setImage('');
    return;
  };
  setFiles(files);
  setTextError([]);
  setFileText(
    <CenterBox width={1}>
      <Typography
        fontSize={14}
        fontWeight={'400'}
        color={palette.secondary.contrastText}
        style={{
          overflow: 'hidden',
          textAlign: 'right',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 150
        }}
      >
        {files[0].name}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={'400'}
        color={palette.secondary.contrastText}
        style={{ width: 130 }}
      >
        を選択しています。
      </Typography>
    </CenterBox>
  );
};

export const onFileDrop = (
  acceptedFiles: File[],
  fileRejections: FileRejection[],
  setFiles: React.Dispatch<React.SetStateAction<File[]>>,
  setFileText: React.Dispatch<any>,
  setTextError: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  if (fileRejections.length > 0) {
    const errors = rejections(fileRejections);
    if (errors.length > 0) {
      setTextError(errors);
      setFileText(<Typography fontSize={14} fontWeight={'400'} color={palette.primary.contrastText}>ドラッグ&ドロップ、タップしてアップロード<br /><span style={{ fontWeight: 'bold' }}>10MB未満のjpg, pngのみ、画像は10枚まで。</span></Typography>);
      setFiles([]);
      return;
    }
  }
  const files = acceptedFiles;
  if (!files || files.length === 0) {
    setFileText(<Typography fontSize={14} fontWeight={'400'} color={palette.primary.contrastText}>ドラッグ&ドロップ、タップしてアップロード<br /><span style={{ fontWeight: 'bold' }}>10MB未満のjpg, pngのみ、画像は10枚まで。</span></Typography>);
    setFiles([]);
    return;
  };
  setFiles(files);
  const fileName = Array.from(files).flatMap((file) => {
    return file.name;
  });
  setTextError([]);
  setFileText(
    <Typography
      fontSize={14}
      fontWeight={'400'}
      color={palette.primary.contrastText}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}
    >
      {files.length}枚の画像を選択しています。
      <br />
      {fileName.flatMap((v, key) => {
        return (<span key={key}>{v}<br /></span>);
      })}
    </Typography>);
};

export const rejections = (fileRejections: FileRejection[]) => {
  const errorList: string[] = [];
  const errorTexts: string[] = [];
  for (let fileRejection of fileRejections) {
    const errors = fileRejection.errors;
    const items = errors.flatMap((v) => { return v.code; });
    errorList.push(...items);
  }
  const errors = errorList.filter((error, idx) => {
    return errorList.indexOf(error) === idx;
  });
  for (let error of errors) {
    if (error === 'file-invalid-type') {
      errorTexts.push('jpg,pngのみ画像をアップロード可能です');
    } else if (error === 'too-many-files') {
      errorTexts.push('画像は10枚までアップロード可能です');
    } else if (error === 'file-too-large') {
      errorTexts.push('10MB未満の画像のみアップロード可能です');
    }
  }
  return errorTexts;
};

export const userRejections = (fileRejections: FileRejection[]) => {
  const errorList: string[] = [];
  const errorTexts: string[] = [];
  for (let fileRejection of fileRejections) {
    const errors = fileRejection.errors;
    const items = errors.flatMap((v) => { return v.code; });
    errorList.push(...items);
  }
  const errors = errorList.filter((error, idx) => {
    return errorList.indexOf(error) === idx;
  });
  for (let error of errors) {
    if (error === 'file-invalid-type') {
      errorTexts.push('jpg,pngのみ画像をアップロード可能です');
    } else if (error === 'too-many-files') {
      errorTexts.push('画像は1枚までアップロード可能です');
    } else if (error === 'file-too-large') {
      errorTexts.push('10MB未満の画像のみアップロード可能です');
    }
  }
  return errorTexts;
};