import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { AlignCenter, CenterBox, CenterColumnBox, CustomButton, DefaultBox, FlexBox, StartColumnBox, palette } from '../../theme';
import { useNavigateToTop } from '../../config';
import { Logo } from '../../images/Logo';
import { Footer } from '../organisms/Footer';
import { AuthContext } from '../../contexts/Auth';

const CustomTitle = styled(Typography)({
  borderBottom: '5px solid #FFFFFF'
});

const CustomBox = styled(Box)({
  padding: '24px 16px',
  background: '#484848'
});

const CustomBalloonBox = styled(Box)({
  position: 'relative',
  padding: '24px 16px',
  background: '#484848',
  '&::before': {
    content: '""',
    border: '8px solid transparent',
    borderBottom: '15px solid #484848',
    position: 'absolute',
    bottom: '100%',
    left: '9%',
    transform: 'translateX(-50%)'
  }
});

export const Information: React.FC = () => {
  const navigate = useNavigateToTop();
  const { user } = useContext(AuthContext);
  return (
    <CenterColumnBox
      pt={5}
      pb={5}
      width={1}
      bgcolor={palette.background.default}
      justifyContent="flex-start"
    >
      <DefaultBox>
        <CenterColumnBox>
          <Box height={1}>
            <Logo width={180} />
          </Box>
          <Box mt={2} />
          <Box
            component={'img'}
            alt={'タイトル'}
            src={'images/title.jpg'}
            width={310}
          />
          <Box mt={2} />
        </CenterColumnBox>
        <Box mt={4} />
        <CustomBox>
          <CenterBox width={1}>
            <CustomTitle variant="h1">マーベリックダービーとは？</CustomTitle>
          </CenterBox>
          <Box mt={3} />
          <Box>
            <Typography variant="subtitle2">マーベリックをご購入頂いた方のみが参加エントリーできるジギングダービーです。</Typography>
            <Box mt={2} />
            <Typography variant="subtitle2">期間内に釣れた魚のサイズを競い合うイベントです。</Typography>
            <Box mt={2} />
            <Typography variant="subtitle2">ランキング上位者には、ささやかな賞品を予定しております。</Typography>
            <Box mt={2} />
            <Typography variant="subtitle2">無料で参加できるので、ぜひ全国各地からのマーベリックでの釣果をお待ちしております。</Typography>
          </Box>
        </CustomBox>
        <Box mt={3} />
        <AlignCenter>
          <Box
            component={'img'}
            alt={'ユーザーアイコン'}
            src={'images/user-icon.jpg'}
            width={60}
            height={1}
          />
          <Box ml={1} />
          <StartColumnBox>
            <Typography variant="subtitle2">孤高のスロージギング</Typography>
            <Box mt={.5} />
            <Typography variant="h2">長尾氏コメント</Typography>
          </StartColumnBox>
        </AlignCenter>
        <Box mt={1.8} />
        <CustomBalloonBox>
          <Typography
            variant="subtitle2"
            lineHeight={1.8}
          >
            たくさんの方々にマーベリックを購入していただきとても嬉しく思っています。皆さんとの交流をもっと深めるべく、オンラインダービーを開催する運びとなりました。ささやかな賞品も用意する予定ですので、楽しみながら、全国でスロージギングの輪が広がっていくと良いなと感じています！ラフに楽しんでいきましょう！
          </Typography>
        </CustomBalloonBox>
        <Box mt={3} />
        <CustomBox>
          <CenterBox width={1}>
            <CustomTitle variant="h1">レギュレーション</CustomTitle>
          </CenterBox>
          <Box mt={3} />
          <Typography variant="subtitle2">対象魚: カンパチ、ハタ類、マグロ・カジキ・ヒラマサ等の青物(サメ・ダツなどの長物系はNGとします)</Typography>
          <Box mt={2} />
          <Typography variant="subtitle2">期 間: 2025年3月31日まで</Typography>
          <Box mt={2} />
          <Typography variant="subtitle2">内 容: 1匹の全長で競います(メジャー使用必須)</Typography>
          <Box mt={2} />
          <Typography variant="subtitle2">賞 品: 未定。随時発表していきます</Typography>
          <Box mt={2} />
          <Typography variant="subtitle2">参加費: 無料</Typography>
          <Box mt={2} />
          <FlexBox>
            <Box width={.14}>
              <Typography variant="subtitle2">特 記:</Typography>
            </Box>
            <Box width={.85}>
              <Typography variant="subtitle2">使用するジグは<span style={{ textDecoration: 'underline' }}>マーベリック以外</span>でもOKです！</Typography>
              <Typography variant="subtitle2">(マーベリックだったら嬉しいです！)</Typography>
              <Box mt={.8} />
              <Typography variant="subtitle2">エントリー数は何匹でもOKですが、ランキングに反映できるのは最大サイズの1匹のみです。</Typography>
            </Box>
          </FlexBox>
        </CustomBox>
        <Box mt={3} />
        <Box
          p={2}
          bgcolor={palette.background.paper}
        >
          <Typography variant="subtitle1">エントリー方法:<span style={{ color: palette.primary.main, textDecoration: 'underline' }}>「エントリーする」</span>ボタンから釣果登録をお願いします。</Typography>
          <Typography variant="subtitle1">その際、対象魚の全長が認識できる写真が必要です。</Typography>
          <Typography variant="subtitle1">計測の際には必ずメジャーを使用してください。サイズが認識できないものに関してはエントリー不可となります。</Typography>
        </Box>
        <Box mt={5} />
        <CustomButton
          variant="contained"
          onClick={() => {
            if (user && user.name) {
              navigate('/registration/?path=i');
              return;
            } else if (!user) {
              navigate('/login');
              return;
            } else if (user && !user.name) {
              navigate('/user-registration');
              return;
            }
            return navigate('/derby');
          }}
        >
          エントリーする
        </CustomButton>
        <Box mt={2} />
        <CenterBox onClick={() => { navigate('/derby'); }}>
          <Typography
            color={palette.primary.contrastText}
            fontWeight={'400'}
            fontSize={14}
            fontFamily={'"Roboto","Helvetica","Arial",sans-serif'}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            ランキング一覧へ
          </Typography>
        </CenterBox>
        <Box mt={6} />
        <Footer />
        <CenterBox
          onClick={() => {
            const url = 'https://maverickokou.theshop.jp/';
            window.open(url, '_blank');
          }}
        >
          <Typography
            color={palette.primary.contrastText}
            fontWeight={'400'}
            fontSize={14}
            fontFamily={'"Roboto","Helvetica","Arial",sans-serif'}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            ダービーに関するお問い合わせはこちら
          </Typography>
        </CenterBox>
        <Box mt={5} />
      </DefaultBox>
    </CenterColumnBox>
  );
}