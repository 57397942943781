import { Box } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import { CustomImageBox } from '../../theme';

interface OwnProps {
  images: string[];
}

export const SliderComponent: React.FC<OwnProps> = (props) => {
  const { images } = props;
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      {images.flatMap((image, idx) => {
        return (
          <Box key={idx} height={200}>
            <CustomImageBox
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'contain'
              }}
            />
            <Box />
          </Box>
        );
      })}
    </Slider>
  )
}
