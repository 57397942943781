import { Avatar, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { CenterColumnBox, CustomButton, CustomTextField, palette, StartColumnBox } from '../../theme';
import { onUserFileDrop, useNavigateToTop, delay } from '../../config';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FileRejection, useDropzone } from 'react-dropzone';
import { AuthContext } from '../../contexts/Auth';
import { StateContext } from '../../contexts/State';
import { TImage } from '../../models/TImage';
import { ProgressDialog } from '../dialogs/ProgressDialog';
import { Title } from '../atoms/Title';
import { BackButton } from '../atoms/BackButton';
import { ErrorTexts } from '../atoms/ErrorTexts';
import { CustomFormLabel } from '../atoms/CustomFormLabel';

export const EditUser: React.FC = () => {
  const navigate = useNavigateToTop();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const { user, updateAuthUser } = useContext(AuthContext);
  const { uploadImage } = useContext(StateContext);
  const nameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [nameErrorText, setNameErrorText] = useState<string>('');
  const [isEmail, setIsEmail] = useState<number>(0);
  const [image, setImage] = useState<string>('');
  const [isDefaultImage, setIsDefaultImage] = useState<boolean>(true);
  const [files, setFiles] = useState<File[]>([]);
  const [fileText, setFileText] = useState<any>(<Typography fontSize={14} fontWeight={'400'} color={palette.secondary.contrastText}>ドラッグ&ドロップ、タップしてアップロード<br />10MB未満のjpg, png</Typography>);
  const [fileTextError, setFileTextError] = useState<string[]>([]);
  const [progress, setProgress] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => onUserFileDrop(acceptedFiles, fileRejections, setFiles, setFileText, setFileTextError, setImage),
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.png']
    },
    maxSize: 1024 * 1024 * 10,
  });

  const handleClick = async () => {
    setProgress(true);
    setProgressValue(10);
    if (!name) {
      setNameErrorText('ユーザー名を入力してください');
      setNameError(true);
      setProgress(false);
      setProgressValue(0);
      return;
    }

    let upload: TImage[] = [];

    await delay(500);
    setProgressValue(40);

    if (files.length > 0 && !isDefaultImage) {
      upload = await uploadImage(files);
    }

    setProgressValue(70);
    await delay(500);

    const update = await updateAuthUser(upload, user!.id, name, isEmail);
    setProgressValue(90);
    await delay(500);
    if (update === 999) {
      setNameErrorText('すでにこのユーザー名は登録されています');
      setNameError(true);
      setProgress(false);
      setProgressValue(0);
    } else if (update === 200) {
      setNameErrorText('');
      setNameError(false);
      setProgressValue(100);
    } else {
      setNameErrorText('予期せぬエラーが発生しました');
      setNameError(true);
      setProgress(false);
      setProgressValue(0);
    }
    return;
  }

  useEffect(() => {
    setName(user ? user.name : '');
    setImage(user ? user.icon : '');
    setIsEmail(user ? user.isEmail : 1);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (files.length > 0) {
      setIsDefaultImage(false);
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setImage(String(e.target.result));
        }
      };
      reader.readAsDataURL(files[0]);
    }
  }, [files]);

  useEffect(() => {
    if (progressValue === 100) {
      setProgress(false);
      setProgressValue(0);
      navigate('/mypage');
    }
    // eslint-disable-next-line
  }, [progressValue]);

  return (
    <Box>
      <BackButton pathName={'/mypage'} />
      <Title />
      <Box mt={2} />
      <Typography variant='h1'>プロフィール編集</Typography>
      <Box mt={3} />
      <Box
        bgcolor={'#f1f1f1'}
        padding={2}
        paddingTop={4}
      >
        <StartColumnBox>
          <Typography variant="subtitle1">アイコン画像</Typography>
          <Box mt={1} />
          <Box
            {...getRootProps()}
            width={1}
            bgcolor={'#e3e3e3'}
            borderRadius={1}
            sx={{ cursor: 'pointer' }}
          >
            <CenterColumnBox
              justifyContent="center"
              p={1}
            >
              <Box
                width={50}
                height={50}
              >
                {image ? (
                  <Avatar
                    alt="アイコン"
                    src={image}
                    sx={{
                      width: '100%',
                      height: '100%'
                    }}
                  />
                ) : (
                  <AccountCircleIcon
                    sx={{
                      width: '100%',
                      height: '100%'
                    }}
                  />
                )}
              </Box>
              <Box
                width={1}
                mt={2}
                mb={1}
              >
                {fileText}
              </Box>
              <input {...getInputProps()} />
            </CenterColumnBox>
          </Box>
          {fileTextError && fileTextError.length > 0 && (
            <ErrorTexts errorTexts={fileTextError} />
          )}
        </StartColumnBox>
        <Box mt={2} />
        <StartColumnBox>
          <CustomFormLabel isRequired={true} text={'ユーザー名'} variant={'subtitle1'} />
          <Box mt={1} />
          <CustomTextField
            error={nameError}
            inputRef={nameRef}
            value={name}
            variant="outlined"
            placeholder="Maverick"
            InputLabelProps={{ shrink: false }}
            helperText={nameRef?.current?.validationMessage}
            InputProps={{
              style: {
                fontSize: 16,
              }
            }}
            sx={{
              '& .Mui-error.MuiFormLabel-root': {
                color: palette.error.main,
              },
              '& .MuiFormHelperText-contained': {
                color: palette.error.main,
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length > 0) setNameError(false);
              setName(value);
            }}
          />
          {nameError && (
            <ErrorTexts errorTexts={[nameErrorText]} />
          )}
        </StartColumnBox>
        <StartColumnBox>
          <FormControlLabel
            sx={{
              '& span': {
                fontSize: 13,
                color: palette.secondary.contrastText
              }
            }}
            control={<Checkbox checked={isEmail === 1} {...label} value={isEmail} onChange={() => { setIsEmail(isEmail === 1 ? 0 : 1); }} />}
            label={isEmail ? '最新のお知らせをメールで受け取ります' : '最新のお知らせをメールで受け取りません'}
          />
        </StartColumnBox>
        <Box mt={4} />
        <CustomButton
          variant="contained"
          disabled={progress}
          onClick={handleClick}
          sx={{
            '&:disabled': {
              background: palette.secondary.light,
              color: 'rgb(255,255,255,.6)'
            }
          }}
        >
          更新する
        </CustomButton>
        <Box mt={1} />
      </Box>
      <Box mt={8} />
      <ProgressDialog open={progress} text={'更新情報をアップロード中...'} value={progressValue} />
    </Box>
  )
}
