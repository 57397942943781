import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { jaJP } from '@mui/x-date-pickers/locales';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: { main: '#003A88', light: '#7FBFFF', contrastText: '#FFFFFF' },
    secondary: { main: '#00155F', light: '#4e70ff', contrastText: '#000000'},
    warning: { main: '#FF2D2D' },
    error: { main: '#FF2D2D' },
    background: { default: '#222222', paper: '#FFFFFF' },
    info: { main: '#FF2D2D'}
  },
  typography: {
    h1: {
      color: '#FFFFFF',
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    h2: {
      color: '#FFFFFF',
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    subtitle1: {
      color: '#000000',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontSize: 14,
      fontWeight: 'bold'
    },
    subtitle2: {
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    body1: {
      fontSize: 10,
      fontWeight: 'bold',
      color: '#FFFFFF',
    },
    body2: {
      fontSize: 8,
      fontWeight: 'bold',
      color: '#FFFFFF',
    },
  },
}, jaJP);

export const palette = theme.palette;

export const DefaultBox = styled(Box)({
  width: 340
});

export const FlexBox = styled(Box)({
  display: 'flex',
});

export const CenterBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const CenterColumnBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

export const StartColumnBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
});

export const EndColumnBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column'
});

export const AlignCenter = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const AlignStretch = styled(Box)({
  display: 'flex',
  alignItems: 'stretch'
});

export const TextLink = styled(Link)({
  color: palette.primary.contrastText
});

export const CustomButton = styled(Button)({
  color: palette.primary.contrastText,
  fontWeight: 'bold',
  background: palette.secondary.light,
  width: '100%',
  "&:hover": {
    backgroundColor: palette.secondary.light,
  }
});

export const OmitTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export const CustomImageBox = styled(Box)({
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
});

export const CustomTextField = styled(TextField)({
  width: '100%',
  '&::placeholder': {
    color: 'rgb(0,0,0,.3)'
  }
});

export const RankingNumber = styled(Box)({
  borderRadius: 30,
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  fontWeight: 'bold',
});