import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { AlignCenter, palette } from '../../theme';
import { Squash as Hamburger } from 'hamburger-react';
import { Logo } from '../../images/Logo';
import { StateContext } from '../../contexts/State';
import { useNavigateToTop } from '../../config';

export const Header: React.FC = () => {
  const navigate = useNavigateToTop();
  const { isHamburgerOpen, setIsHamburgerOpen } = useContext(StateContext);
  return (
    <AlignCenter justifyContent="space-between">
      <Box
        height={1}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigate('/derby');
        }}
      >
        <Logo width={105} />
      </Box>
      <Hamburger
        label="Show menu"
        color={palette.background.paper}
        toggled={isHamburgerOpen}
        toggle={setIsHamburgerOpen}
      />
    </AlignCenter>
  )
}
