import { Box, Divider, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { useNavigateToTop } from '../../config';
import { NewsContext } from '../../contexts/News';
import { Title } from '../atoms/Title';
import { BackButton } from '../atoms/BackButton';
import { CenterBox, CenterColumnBox, CustomButton, palette } from '../../theme';
import { StateContext } from '../../contexts/State';
import { TailSpin } from 'react-loader-spinner';

export const AllNews: React.FC = () => {
  const navigate = useNavigateToTop();
  const { allNews, getNews, getAllNews } = useContext(NewsContext);
  const { isLoadingAllNews } = useContext(StateContext);

  useEffect(() => {
    (async () => {
      await getAllNews();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <BackButton pathName={'/derby'} />
      <Title />
      <Box mt={2} />
      <Typography variant="h2">News一覧</Typography>
      <Box mt={3} />
      {allNews.length === 0 && !isLoadingAllNews && (
        <CenterBox height={200}>
          <Typography fontSize={16}>最新のお知らせはありません</Typography>
        </CenterBox>
      )}
      {allNews.length === 0 && isLoadingAllNews && (
        <CenterBox height={200}>
          <TailSpin
            color={palette.background.paper}
            height={100}
            width={100}
          />
        </CenterBox>
      )}
      {allNews.length === 0 && (
        <>
          <Box mt={5} />
          <CenterColumnBox height={200}>
            <Box
              width={.8}
              display='flex'
              justifyContent="center"
            >
              <CustomButton
                onClick={() => {
                  navigate('/derby');
                }}
              >
                ホームへ
              </CustomButton>
            </Box>
          </CenterColumnBox>
        </>
      )}
      {allNews.length > 0 && (
        <>
          <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
          {allNews.flatMap((news, idx) => {
            return (
              <Box key={idx}>
                <Box
                  p={1}
                  pt={2}
                  pb={2}
                  sx={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await getNews(news.ID);
                    navigate(`/news/${news.ID}/?path=n`);
                  }}
                >
                  <Typography
                    variant='h2'
                    fontSize={15}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {news.postTitle}
                  </Typography>
                  <Box mt={1} />
                  <Typography
                    sx={{
                      color: 'rgb(255,255,255,.6)',
                      fontSize: 12,
                      fontWeight: 'bold',
                      textAlign: 'end'
                    }}
                  >
                    {news.postDate.format('YYYY年MM月DD日')}
                  </Typography>
                </Box>
                <Divider style={{ background: 'rgba(255, 255, 255, 0.38)' }} />
              </Box>
            );
          })}
        </>
      )}
      <Box mt={8} />
    </Box>
  )
}