import React from 'react';
import { Box, Typography } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { palette } from '../../theme';

interface OwnProps {
  text: string;
}

export const Comment: React.FC<OwnProps> = (props) => {
  const { text } = props;
  return (
    <>
      <ChatBubbleIcon
        sx={{
          color: palette.primary.contrastText,
          width: 20,
          height: 20
        }}
      />
      <Box ml={.5} />
      <Typography variant="subtitle2">
        {text}
      </Typography>
    </>
  )
}
