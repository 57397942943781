import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { TColumnData, TData } from '../../models/TRanking';
import { TFish } from '../../models/TFish';
import { useNavigateToTop } from '../../config';
import { CenterBox, palette } from '../../theme';
import { TailSpin } from 'react-loader-spinner';
import { Box } from '@mui/material';

interface OwnProps {
  posts: TFish[];
  isRowTables: boolean;
  rowTables: TData[];
}

const fixedHeaderContent = (isShow: boolean, columns: TColumnData[]) => {
  return (
    <TableRow>
      {!isShow && columns.filter((v) => v.label !== '順位' && v.label !== 'アイコン').flatMap((column) => {
        return (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={'center'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: '#838383',
              color: 'primary.contrastText',
              fontSize: 10,
              fontWeight: 'bold',
              padding: .5,
            }}
          >
            {column.label}
          </TableCell>
        );
      })}
      {isShow && columns.flatMap((column) => {
        return (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={'center'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: '#838383',
              color: 'primary.contrastText',
              fontSize: 10,
              fontWeight: 'bold',
              padding: .5,
            }}
          >
            {column.label === 'アイコン' ? '' : column.label}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const rowContent = (
  _index: number,
  row: TData,
  isShow: boolean,
  columns: TColumnData[]
) => {
  return (
    <React.Fragment>
      {!isShow && columns.filter((v) => v.dataKey !== 'rank' && v.dataKey !== 'rankIcon').flatMap((column) => {
        return (
          <TableCell
            key={column.dataKey}
            align={'center'}
            sx={{
              backgroundColor: 'background.default',
              color: 'primary.contrastText',
              fontSize: 12,
              padding: .5,
              cursor: 'pointer'
            }}
          >
            {row[column.dataKey as keyof TData]}
          </TableCell>
        );
      })}
      {isShow && columns.flatMap((column) => {
        let val = row[column.dataKey as keyof TData];
        if (column.dataKey === 'rank' && Number(row.isRank)) {
          val = row[column.dataKey as keyof TData];
        } else if (column.dataKey === 'rank' && !Number(row.isRank)) {
          val = 'ー';
        }
        if (column.dataKey === 'rankIcon' && Number(row.isRank)) {
          val = row[column.dataKey as keyof TData];
        } else if (column.dataKey === 'rankIcon' && !Number(row.isRank)) {
          val = '';
        }
        return (
          <TableCell
            key={column.dataKey}
            align={'center'}
            sx={{
              backgroundColor: 'background.default',
              color: 'primary.contrastText',
              fontSize: 12,
              padding: .5,
              cursor: 'pointer'
            }}
          >
            {val}
          </TableCell>
        );
      })}
    </React.Fragment>
  );
}

export const RankingTable: React.FC<OwnProps> = (props) => {
  const { posts, isRowTables, rowTables } = props;
  const navigate = useNavigateToTop();
  const isShow = posts.length > 0 ? posts[0].isShow === 1 : false;
  const columns: TColumnData[] = [
    {
      width: 25,
      label: '順位',
      dataKey: 'rank',
    },
    {
      width: 20,
      label: 'アイコン',
      dataKey: 'rankIcon'
    },
    {
      width: isShow ? 62 : 88,
      label: 'ユーザー',
      dataKey: 'user'
    },
    {
      width: isShow ? 38 : 60,
      label: 'サイズ',
      dataKey: 'size'
    },
    {
      width: isShow ? 28 : 38,
      label: '日付',
      dataKey: 'date',
    },
    {
      width: isShow ? 60 : 88,
      label: '遊漁船',
      dataKey: 'boatName',
    },
    {
      width: 15,
      label: '',
      dataKey: 'direction',
    },
  ];
  const VirtuosoTableComponents: TableComponents<TData> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef((props: React.ComponentPropsWithoutRef<'thead'>, ref) => (
      <TableHead ref={ref} {...props} />
    )),
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} onClick={() => {
      const dataIndex = props['data-index'];
      const rankPosts = posts.filter((post) => Number(post.isRank));
      const noRankPosts = posts.filter((post) => !Number(post.isRank));
      const sortPostRanks = [...rankPosts].slice().sort((a, b) => b.size - a.size).slice(4, posts.length);
      const sortPostNoRanks = [...noRankPosts].slice().sort((a, b) => b.size - a.size);
      const post = [...sortPostRanks, ...sortPostNoRanks][dataIndex];
      navigate(`/detail/${post.id}/?path=d`);
    }} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  return (
    <Paper
      style={{
        borderRadius: 'unset',
        boxShadow: 'unset',
        height: 250,
        width: '100%',
      }}
    >
      {isRowTables ? (
        <TableVirtuoso
          data={rowTables}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() => fixedHeaderContent(isShow, columns)}
          itemContent={(_index, row) => rowContent(_index, row, isShow, columns)}
          style={{
            background: palette.background.default,
            boxShadow: 'unset',
            borderRadius: 'unset'
          }}
        />
      ) : (
        <Box position="relative" height={1}>
          <TableVirtuoso
            data={[]}
            components={VirtuosoTableComponents}
            fixedHeaderContent={() => fixedHeaderContent(isShow, columns)}
            itemContent={(_index, row) => rowContent(_index, row, isShow, columns)}
            style={{
              background: palette.background.default,
              boxShadow: 'unset',
              borderRadius: 'unset'
            }}
          />
          <CenterBox
            height={250}
            bgcolor={'rgb(34, 34, 34)'}
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            left={0}
            margin="auto"
          >
            <TailSpin
              color={palette.background.paper}
              height={100}
              width={100}
            />
          </CenterBox>
        </Box>
      )}
    </Paper>
  );
}