import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { AuthContext } from '../../contexts/Auth';
import { Title } from '../atoms/Title';
import { AlignCenter, CenterBox, CenterColumnBox, CustomImageBox, FlexBox, OmitTypography, RankingNumber, palette } from '../../theme';
import { rankingColors, rankingIcon, useNavigateToTop } from '../../config';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FishContext } from '../../contexts/Fish';
import { TFish } from '../../models/TFish';
import { UserIcon } from '../atoms/UserIcon';
import UserSvgIcon from '../../images/user.svg';

const CustomUserName = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  width: 320
});

const CustomButton = styled(Button)({
  borderColor: palette.background.paper,
  color: palette.primary.contrastText,
  padding: '4px 24px',
  fontSize: 12,
  fontWeight: 'bold',
  '&:hover': {
    borderColor: palette.primary.contrastText,
  }
});

export const MyPage: React.FC = () => {
  const navigate = useNavigateToTop();
  const { user } = useContext(AuthContext);
  const { fishes, getFishesData } = useContext(FishContext);
  const [posts, setPosts] = useState<TFish[]>([]);

  useEffect(() => {
    if (!user) {
      window.location.href = '/login';
    } else if (!user.name) {
      window.location.href = '/user-registration';
    }

    const filter = fishes.filter((v) => v.maverickId === user!.id);
    setPosts(filter);
  }, [user, fishes]);

  useEffect(() => {
    (async () => {
      await getFishesData();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Title />
      <Box mt={2} />
      <Typography variant="h2">プロフィール</Typography>
      <Box mt={3} />
      {user && (
        <CenterColumnBox>
          {user.icon ? (
            <Avatar
              alt="アイコン"
              src={user.icon ? user.icon : UserSvgIcon}
              sx={{
                width: 80,
                height: 80
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: 80,
                height: 80,
                color: palette.background.paper
              }}
            />
          )}
          <Box mt={2} />
          <CustomUserName>{user.name}</CustomUserName>
          <Box>
            <Box mt={1} />
            <CustomButton
              variant="outlined"
              onClick={() => {
                navigate('/mypage/edit');
              }}
            >
              プロフィール編集
            </CustomButton>
          </Box>
          <Box mt={3} />
          {posts.length === 0 && (
            <CenterBox height={100}></CenterBox>
          )}
          {posts.length > 0 && (
            <Box>
              {posts.flatMap((post, idx) => {
                const rankIcon = rankingIcon(post);
                const rankingColor = rankingColors.find((rankingColor) => rankingColor.label === post.rank);
                return (
                  <Box
                    key={idx}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/detail/${post.id}/?path=m`);
                      return;
                    }}
                  >
                    <CenterBox
                      flexDirection="column"
                      width={300}
                      height={1}
                    >
                      <Box
                        position="relative"
                        width={300}
                        height={300}
                      >
                        <CustomImageBox
                          style={{
                            backgroundImage: `url(${post.images[0]})`,
                          }}
                        />
                        <Box
                          position="absolute"
                          bottom={0}
                          width={1}
                          bgcolor={'rgb(40,47,55,.8)'}
                        >
                          <AlignCenter
                            p={.5}
                            justifyContent="space-between"
                          >
                            {post.isShow === 1 && (
                              <AlignCenter>
                                <RankingNumber
                                  bgcolor={rankingColor ? rankingColor.colorCode : palette.background.paper}
                                  width={25}
                                  height={25}
                                  style={{ fontSize: 13 }}
                                >
                                  {post.rank}
                                </RankingNumber>
                                <Box ml={.3} />
                                {rankIcon ? rankIcon.icon : <Box />}
                              </AlignCenter>
                            )}
                            <Box ml={1} />
                            <Typography variant="body1" fontSize={15}>{post.name} {post.size}cm</Typography>
                          </AlignCenter>
                        </Box>
                      </Box>
                      <AlignCenter
                        pt={.5}
                        pb={.5}
                        width={300}
                        justifyContent="space-between"
                      >
                        <FlexBox
                          pl={.5}
                          width={130}
                          alignItems="flex-end"
                        >
                          <UserIcon icon={user ? user.icon : ''} width={20} height={20} color={palette.secondary.contrastText} />
                          <Box ml={.5} />
                          <Box maxWidth={70}>
                            <OmitTypography
                              variant="body1"
                              fontSize={15}
                              lineHeight={1.4}
                            >
                              {user.name}
                            </OmitTypography>
                          </Box>
                          <Typography
                            variant="body2"
                            fontSize={13}
                          >
                            さん
                          </Typography>
                        </FlexBox>
                        <Box pr={.5}>
                          <Typography variant="body1" fontSize={15}>{post.createdAt.format('YYYY.MM.DD')}</Typography>
                        </Box>
                      </AlignCenter>
                    </CenterBox>
                    <Box mt={3} />
                  </Box>
                );
              })}
            </Box>
          )}
          <Box mt={5} />
        </CenterColumnBox>
      )}
    </Box>
  )
}