import React from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { TComment } from '../../models/TComment';
import { CenterBox } from '../../theme';

interface OwnProps {
  currentComments: TComment[];
  setCount: React.Dispatch<React.SetStateAction<number>>;
  setIsMore: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomBox = styled(Box)({
  background: '#626262',
  borderRadius: '5.2px',
  width: '50%',
});

const CustomTypography = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
});

export const MoreInfoButton: React.FC<OwnProps> = (props) => {
  const { currentComments, setCount, setIsMore } = props;
  return (
    <>
      <Box mt={2} />
      <CenterBox
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setCount(currentComments.length);
          setIsMore(true);
        }}
      >
        <CustomBox p={1}>
          <CustomTypography>もっと見る＋</CustomTypography>
        </CustomBox>
      </CenterBox>
    </>
  )
}