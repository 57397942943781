import React from 'react';
import { AlignCenter } from '../../theme';
import { Box, Typography } from '@mui/material';

export const Title: React.FC = () => {
  return (
    <AlignCenter>
      <Typography variant="h1">Maverick Derby</Typography>
      <Box ml={3} />
      <Typography variant="body1" fontSize={14}>期間: 6/1~3/31</Typography>
    </AlignCenter>
  )
}
