import { Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';
import React from 'react';

interface OwnProps {
  isRequired: boolean;
  text: string;
  variant: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export const CustomFormLabel: React.FC<OwnProps> = (props) => {
  const { isRequired, text, variant } = props;
  return (
    <Typography variant={variant}>
      {text}
      {isRequired && (
        <span style={{ color: '#FF2D2D', marginLeft: 8 }}>※必須</span>
      )}
    </Typography>
  )
}
