import React from 'react';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import { theme } from './theme';
import { RequireAuth } from './components/atoms/RequireAuth';
import { Page } from './components/molecules/Page';
import { NoFooterPage } from './components/molecules/NoFooterPage';
import { NotFound } from './components/pages/NotFound';
import { Provider } from './components/atoms/Provider';
import { Login } from './components/pages/Login';
import { Information } from './components/pages/Information';
import { Ranking } from './components/pages/Ranking';
import { Detail } from './components/pages/Detail';
import { Registration } from './components/pages/Registration';
import { UserRegistration } from './components/pages/UserRegistration';
import { MyPage } from './components/pages/MyPage';
import { EditUser } from './components/pages/EditUser';
import { RegistrationConfirm } from './components/pages/RegistrationConfirm';
import { News } from './components/pages/News';
import { AllNews } from './components/pages/AllNews';

export const App = () => {

	return (
		<ThemeProvider theme={theme}>
			<Provider>
				<Router>
					<Routes>
						<Route path="/login" element={
							<RequireAuth>
								<Login />
							</RequireAuth>
						} />
						<Route path="/info" element={
							<Information />
						} />
						<Route path="/user-registration" element={
							<RequireAuth>
								<UserRegistration />
							</RequireAuth>
						} />
						<Route path="/" element={
							<NoFooterPage component={Ranking} />
						} />
						<Route path="/derby" element={
							<NoFooterPage component={Ranking} />
						} />
						<Route path="/detail/:id" element={
							<Page component={Detail} />
						} />
						<Route path="/detail/:id/:path" element={
							<Page component={Detail} />
						} />
						<Route path="/registration" element={
							<RequireAuth>
								<Page component={Registration} />
							</RequireAuth>
						} />
						<Route path="/registration/:path" element={
							<RequireAuth>
								<Page component={Registration} />
							</RequireAuth>
						} />
						<Route path="/registration-confirm" element={
							<RequireAuth>
								<Page component={RegistrationConfirm} />
							</RequireAuth>
						} />
						<Route path="/mypage" element={
							<RequireAuth>
								<Page component={MyPage} />
							</RequireAuth>
						} />
						<Route path="/mypage/edit" element={
							<RequireAuth>
								<Page component={EditUser} />
							</RequireAuth>
						} />
						<Route path="/news" element={
							<Page component={AllNews} />
						} />
						<Route path="/news/:id" element={
							<Page component={News} />
						} />
						<Route path="/news/:id/:path" element={
							<Page component={News} />
						} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Router>
			</Provider>
		</ThemeProvider>
	);
}