import React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/system';
import { CenterColumnBox, FlexBox } from '../../theme';
import { LinearProgressWithLabel } from '../atoms/ProgressBar';

interface OwnProps {
  open: boolean;
  text: string;
  value: number;
}

const CustomDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    margin: 0,
    maxWidth: 340,
  }
});

export const ProgressDialog: React.FC<OwnProps> = (props) => {
  const { open, text, value } = props;
  return (
    <CustomDialog
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box p={2} width={300}>
        <CenterColumnBox>
          <ErrorOutlineIcon />
          <Box mt={1} />
          <Typography variant="subtitle1" fontSize={15}>
            {text}
          </Typography>
        </CenterColumnBox>
        <Box mt={3} />
        <FlexBox>
          <LinearProgressWithLabel value={value}/>
        </FlexBox>
      </Box>
    </CustomDialog>
  )
}
