import React, { useContext, useEffect, useRef, useState } from 'react';
import { CenterColumnBox, CustomButton, CustomTextField, DefaultBox, palette, StartColumnBox } from '../../theme';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Logo } from '../../images/Logo';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FileRejection, useDropzone } from 'react-dropzone';
import { onUserFileDrop, useNavigateToTop, delay } from '../../config';
import { useWindowSize } from 'react-use';
import { AuthContext } from '../../contexts/Auth';
import { StateContext } from '../../contexts/State';
import { TImage } from '../../models/TImage';
import { ProgressDialog } from '../dialogs/ProgressDialog';
import { Sponsor } from '../../images/Sponsor';
import { ErrorTexts } from '../atoms/ErrorTexts';

export const UserRegistration: React.FC = () => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const navigate = useNavigateToTop();
  const { height } = useWindowSize();
  const { users, getUsers, updateAuthUser } = useContext(AuthContext);
  const { uploadImage } = useContext(StateContext);
  const nameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [nameErrorText, setNameErrorText] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [fileText, setFileText] = useState<any>(<Typography fontSize={14} fontWeight={'400'} color={palette.secondary.contrastText}>ドラッグ&ドロップ、タップしてアップロード<br /><span style={{ fontWeight: 'bold' }}>10MB未満のjpg, png</span></Typography>);
  const [fileTextError, setFileTextError] = useState<string[]>([]);
  const [progress, setProgress] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [isEmail, setIsEmail] = useState<boolean>(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => onUserFileDrop(acceptedFiles, fileRejections, setFiles, setFileText, setFileTextError, setImage),
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.png']
    },
    maxSize: 1024 * 1024 * 10,
  });

  const handleClick = async () => {
    setProgress(true);
    setProgressValue(10);
    if (!name) {
      setNameErrorText('ユーザー名を入力してください');
      setNameError(true);
      setProgress(false);
      return;
    }

    const findUser = users.find((user) => user.name === name);
    if (findUser) {
      setNameErrorText('すでにこのユーザー名は登録されています');
      setNameError(true);
      setProgress(false);
      return;
    }

    await delay(500);
    setProgressValue(40);

    let upload: TImage[] = [];
    if (files.length > 0) {
      upload = await uploadImage(files);
    }

    setProgressValue(70);
    await delay(500);
    const isMailFormat = isEmail ? 1 : 0;
    const update = await updateAuthUser(upload, null, name, isMailFormat);
    setProgressValue(90);
    await delay(500);
    if (update === 999) {
      setNameErrorText('すでにこのユーザー名は登録されています');
      setNameError(true);
      setProgress(false);
      setProgressValue(0);
    } else if (update === 200) {
      setNameErrorText('');
      setNameError(false);
      setProgressValue(100);
    } else {
      setNameErrorText('予期せぬエラーが発生しました');
      setNameError(true);
      setProgress(false);
      setProgressValue(0);
    }
    return;
  }

  useEffect(() => {
    if (files.length > 0) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setImage(String(e.target.result));
        }
      };
      reader.readAsDataURL(files[0]);
    } else {
      setImage('');
    }
  }, [files]);

  useEffect(() => {
    (async () => {
      if (progressValue === 100) {
        setProgress(false);
        setProgressValue(0);
        navigate('/info');
        await getUsers();
      }
    })();
    // eslint-disable-next-line
  }, [progressValue]);

  const handleClickLogo = () => {
    const url = "https://qoodeta098.studio.site/";
    window.open(url, '_blank');
  };

  return (
    <CenterColumnBox
      pt={5}
      pb={5}
      width={1}
      minHeight={height}
      bgcolor={palette.background.default}
      justifyContent="flex-start"
    >
      <DefaultBox>
        <CenterColumnBox>
          <Box height={1}>
            <Logo width={180} />
          </Box>
        </CenterColumnBox>
        <Box mt={4} />
        <Box>
          <Typography variant='h1' textAlign="center">プロフィール登録</Typography>
        </Box>
        <Box mt={3} />
        <Box
          bgcolor={'#f1f1f1'}
          padding={2}
          paddingTop={4}
        >
          <StartColumnBox>
            <Typography variant="subtitle1">アイコン画像</Typography>
            <Box mt={1} />
            <Box
              {...getRootProps()}
              width={1}
              bgcolor={'#e3e3e3'}
              borderRadius={1}
              sx={{ cursor: 'pointer' }}
            >
              <CenterColumnBox
                justifyContent="center"
                p={1}
              >
                <Box
                  width={50}
                  height={50}
                >
                  {image ? (
                    <Box
                      component={'img'}
                      src={image}
                      alt={'ユーザーアイコン'}
                      borderRadius={10}
                      width={1}
                      height={1}
                    />
                  ) : (
                    <AccountCircleIcon
                      sx={{
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  )}
                </Box>
                <Box
                  width={1}
                  mt={2}
                  mb={1}
                >
                  {fileText}
                </Box>
                <input {...getInputProps()} />
              </CenterColumnBox>
            </Box>
            {fileTextError && fileTextError.length > 0 && (
              <ErrorTexts errorTexts={fileTextError} />
            )}
          </StartColumnBox>
          <Box mt={2} />
          <StartColumnBox>
            <Typography variant="subtitle1">
              ユーザー名
              <span style={{ color: '#FF2D2D', marginLeft: 8 }}>※必須</span>
            </Typography>
            <Box mt={1} />
            <CustomTextField
              error={nameError}
              inputRef={nameRef}
              variant="outlined"
              placeholder="Maverick"
              InputLabelProps={{ shrink: false }}
              helperText={nameRef?.current?.validationMessage}
              InputProps={{
                style: {
                  fontSize: 16,
                }
              }}
              sx={{
                '& .Mui-error.MuiFormLabel-root': {
                  color: palette.error.main,
                },
                '& .MuiFormHelperText-contained': {
                  color: palette.error.main,
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 0) setNameError(false);
                setName(value);
              }}
            />
            {nameError && (
              <ErrorTexts errorTexts={[nameErrorText]} />
            )}
          </StartColumnBox>
          <Box mt={2} />
          <StartColumnBox>
            <FormControlLabel
              sx={{
                '& span': {
                  fontSize: 13,
                  color: palette.secondary.contrastText
                }
              }}
              control={<Checkbox checked={isEmail} {...label} value={isEmail} onChange={() => { setIsEmail(!isEmail); }} />}
              label={isEmail ? '最新のニュースをメールで受け取ります' : '最新のニュースをメールで受け取りません'}
            />
          </StartColumnBox>
          <Box mt={4} />
          <CustomButton
            variant="contained"
            onClick={handleClick}
            disabled={progress}
            sx={{
              '&:disabled': {
                background: palette.secondary.light,
                color: 'rgb(255,255,255,.6)'
              }
            }}
          >
            登録する
          </CustomButton>
          <Box mt={1} />
        </Box>
        <Box mt={10} />
        <CenterColumnBox
          sx={{ cursor: 'pointer' }}
          onClick={handleClickLogo}
        >
          <Typography
            fontSize={9}
            fontWeight={'bold'}
          >
            PRODUCED BY
          </Typography>
          <Sponsor width={70} height={30} />
        </CenterColumnBox>
      </DefaultBox>
      <Box pt={3} />
      <ProgressDialog open={progress} text={'プロフィール情報をアップロード中...'} value={progressValue} />
    </CenterColumnBox>
  )
}