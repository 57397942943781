import React, { useEffect, useState } from 'react';
import { CenterColumnBox, palette } from '../../theme';
import { Collapse, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TNews } from '../../models/TNews';
import { TailSpin } from 'react-loader-spinner';
import { useNavigateToTop } from '../../config';

interface OwnProps {
  isLoadingAllNews: boolean;
  isNewsMenu: boolean;
  setIsNewsMenu: React.Dispatch<React.SetStateAction<boolean>>;
  allNews: TNews[];
  getNews: any;
}

export const NewsComponent: React.FC<OwnProps> = (props) => {
  const { isLoadingAllNews, isNewsMenu, setIsNewsMenu, allNews, getNews } = props;
  const navigate = useNavigateToTop();
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  useEffect(() => {
    if (allNews.length === 0) return;
    if (isNewsMenu && allNews.length > 1) {
      setIsCollapse(true);
    } else if (!isNewsMenu && allNews.length > 1){
      setIsCollapse(false);
    } else {
      setIsCollapse(false);
    }
  },[isNewsMenu, allNews]);

  return (
    <CenterColumnBox
      bgcolor={palette.primary.contrastText}
      borderRadius={'5.2px 5.2px 0 0'}
    >
      <List
        sx={{
          width: '100%',
          padding: 0,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          disableRipple
          sx={{
            background: palette.info.main,
            borderRadius: '5.2px 5.2px 0 0',
            '&:hover': {
              background: palette.info.main,
            }
          }}
          onClick={() => {
            setIsNewsMenu(!isNewsMenu);
          }}
        >
          <ListItemText
            primary="News"
            primaryTypographyProps={{
              color: '#FFFFFF',
              fontSize: 16,
              fontWeight: 'bold',
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              textAlign: 'center'
            }}
          />
          {!isNewsMenu ? <ExpandLessIcon sx={{ color: palette.primary.contrastText }} /> : <ExpandMoreIcon sx={{ color: palette.primary.contrastText }} />}
        </ListItemButton>
        {isCollapse && (
          <List component="div" disablePadding sx={{ display: isCollapse ? 'block' : 'none' }}>
            {allNews.length === 0 && !isLoadingAllNews && (
              <ListItem
                sx={{
                  height: 100,
                  background: palette.background.paper
                }}
              >
                <Typography color={palette.secondary.contrastText} fontSize={16}>最新のニュースはありません</Typography>
              </ListItem>
            )}
            {allNews.length === 0 && isLoadingAllNews && (
              <ListItem
                sx={{
                  height: 100,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <TailSpin
                  color={palette.secondary.light}
                  height={50}
                  width={50}
                />
              </ListItem>
            )}
            {allNews.length > 0 && (
              <>
                {allNews.slice(0, 1).flatMap((news, idx) => {
                  return (
                    <ListItem
                      key={idx}
                      sx={{ cursor: 'pointer' }}
                      onClick={async () => {
                        await getNews(news.ID);
                        navigate(`/news/${news.ID}`);
                      }}
                    >
                      <ListItemText
                        primary={news.postTitle}
                        primaryTypographyProps={{
                          color: '#000000',
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontSize: 14,
                          fontWeight: '400',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        secondary={news.postDate.format('YYYY年MM月DD日')}
                        secondaryTypographyProps={{
                          color: 'rgb(0,0,0,.3)',
                          fontSize: 12,
                          fontWeight: 'bold',
                          textAlign: 'end'
                        }}
                      />
                    </ListItem>
                  );
                })}
              </>
            )}
          </List>
        )}
        <Collapse in={!isCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {allNews.length === 0 && !isLoadingAllNews && (
              <ListItem
                sx={{
                  height: 100,
                  background: palette.background.paper
                }}
              >
                <Typography color={palette.secondary.contrastText} fontSize={16}>最新のお知らせはありません</Typography>
              </ListItem>
            )}
            {allNews.length === 0 && isLoadingAllNews && (
              <ListItem
                sx={{
                  height: 100,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <TailSpin
                  color={palette.secondary.light}
                  height={50}
                  width={50}
                />
              </ListItem>
            )}
            {allNews.length > 0 && (
              <>
                {allNews.slice(0, 3).flatMap((news, idx) => {
                  return (
                    <ListItem
                      key={idx}
                      sx={{ cursor: 'pointer' }}
                      onClick={async () => {
                        await getNews(news.ID);
                        navigate(`/news/${news.ID}`);
                      }}
                    >
                      <ListItemText
                        primary={news.postTitle}
                        primaryTypographyProps={{
                          color: '#000000',
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontSize: 14,
                          fontWeight: '400',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        secondary={news.postDate.format('YYYY年MM月DD日')}
                        secondaryTypographyProps={{
                          color: 'rgb(0,0,0,.3)',
                          fontSize: 12,
                          fontWeight: 'bold',
                          textAlign: 'end'
                        }}
                      />
                    </ListItem>
                  );
                })}
                {allNews.length > 3 && (
                  <ListItem>
                    <ListItemButton
                      sx={{
                        fontWeight: 'bold',
                        background: palette.secondary.light,
                        borderRadius: 1.3,
                        "&:hover": {
                          backgroundColor: palette.secondary.light,
                        }
                      }}
                      onClick={() => {
                        navigate(`/news`);
                      }}
                    >
                      <ListItemText
                        primary="ニュース一覧"
                        primaryTypographyProps={{
                          color: palette.primary.contrastText,
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      />
                      <ChevronRightIcon sx={{ color: palette.primary.contrastText }} />
                    </ListItemButton>
                  </ListItem>
                )}
              </>
            )}
          </List>
        </Collapse>
      </List>
    </CenterColumnBox>
  )
}