import React, { useContext } from 'react';
import { Login } from '../pages/Login';
import { AuthContext } from '../../contexts/Auth';
import { TContext } from '../../models/TContext';
import { BasicBackdrop } from './BasicBackdrop';
import { UserRegistration } from '../pages/UserRegistration';
import { StateContext } from '../../contexts/State';

export const RequireAuth: React.FC<TContext> = (props: any) => {
  const pathName = window.location.pathname;
  const { user, userLoading } = useContext(AuthContext);
  const { isComment } = useContext(StateContext);
  if (userLoading) return <BasicBackdrop open={true} />;
  if (pathName.indexOf('derby') < 0) {
    if (!user) {
      window.history.replaceState('', '', '/login');
      return <Login />;
    }
    if (!user.name) {
      window.history.replaceState('', '', '/user-registration');
      return <UserRegistration />;
    }
    if (pathName === '/login' && user && user.name) {
      if (isComment) {
        window.location.href = `/detail/${isComment}`;
      } else {
        window.location.href = '/derby';
      }
      return;
    }
    if (pathName === '/user-registration' && user && user.name) {
      if (isComment) {
        window.location.href = `/detail/${isComment}`;
      } else {
        window.location.href = '/info';
      }
      return;
    }
  }
  return props.children;
}