import React from 'react';
import { Box, Button } from '@mui/material';
import { palette } from '../../theme';
import { useNavigateToTop } from '../../config';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

interface OwnProps {
  pathName: string;
}

export const BackButton: React.FC<OwnProps> = (props) => {
  const { pathName } = props;
  const navigate = useNavigateToTop();
  return (
    <Box>
      <Button
        sx={{
          color: palette.primary.contrastText,
          fontSize: 16
        }}
        variant='text'
        startIcon={<ArrowBackIosNewIcon />}
        onClick={() => { navigate(pathName); }}
      >
        戻る
      </Button>
      <Box mt={1} />
    </Box>
  )
}
