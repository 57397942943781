import { Box, Drawer } from '@mui/material';
import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { palette } from '../../theme';
import { useWindowSize } from 'react-use';
import { Header } from '../organisms/Header';
import { SideMenu } from '../organisms/SideMenu';
import { StateContext } from '../../contexts/State';

interface OwnProps {
  component: React.FC;
}

const CustomBox = styled(Box)({
  width: 340,
  position: 'relative',
});

export const NoFooterPage: React.FC<OwnProps> = (props) => {
  const { height, width } = useWindowSize();
  const { isHamburgerOpen } = useContext(StateContext);

  return (
    <Box
      pb={3}
      bgcolor={palette.background.default}
      display="flex"
      justifyContent="center"
      minHeight={height}
    >
      <CustomBox>
        <Header />
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={isHamburgerOpen}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& [class^=MuiPaper]': {
              width: width,
              background: palette.background.default,
            }
          }}
        >
          <SideMenu />
        </Drawer>
        <Box>
          <props.component />
        </Box>
      </CustomBox>
    </Box>
  )
}