import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AlignCenter, AlignStretch, FlexBox, OmitTypography, palette } from '../../theme';
import { CommentContext } from '../../contexts/Comment';
import { TReplayComment } from '../../models/TComment';
import { AuthContext } from '../../contexts/Auth';
import { Comment } from '../atoms/Comment';
import { MoreInfoButton } from '../atoms/MoreInfoButton';
import { UserIcon } from '../atoms/UserIcon';

interface OwnProps {
  onHandleClickReplay: (id: number, commentAuthor: string) => void;
}

export const CommentBox: React.FC<OwnProps> = (props) => {
  const { onHandleClickReplay } = props;
  const { user } = useContext(AuthContext);
  const { currentComments, replayComments } = useContext(CommentContext);
  const [count, setCount] = useState<number>(5);
  const [isMore, setIsMore] = useState<boolean>(false);
  const [isMoreComment, setIsMoreComment] = useState<number[]>([]);
  const elCommentBox = document.getElementById('comment-box');

  return (
    <>
      {currentComments.slice(0, count).flatMap((currentComment, idx) => {
        const mainReplay = replayComments.filter((v) => v.id === currentComment.id);
        const replayList: TReplayComment[] = [];
        for (let e = 0; e < mainReplay.length; e++) {
          const filter = replayComments.filter((n) => n.id === mainReplay[e].replayId);
          if (filter.length > 0) {
            replayList.push(...filter);
          }
          replayList.push(mainReplay[e]);
        }
        replayList.sort((a, b) => a.replayId - b.replayId);
        for (let k = 0; k < replayList.length; k++) {
          const filter = replayComments.filter((v) => v.id === replayList[k].replayId);
          if (filter.length > 0) {
            replayList.push(...filter);
          }
        }
        const replay = replayList.filter(
          (element, index, self) => self.findIndex((e) => (
            e.id === element.id &&
            e.replayId === element.replayId
          )) === index
        );
        replay.sort((a, b) => a.replayId - b.replayId);
        const userIcon = currentComment.icon;

        return (
          <Box key={idx}>
            <Box mt={1} />
            <Box
              bgcolor={'#626262'}
              borderRadius={1.3}
              p={2}
            >
              <Typography fontSize={14}>{currentComment.commentContent}</Typography>
              <Box mt={1} />
              <AlignCenter justifyContent="space-between">
                <AlignStretch width={.6}>
                  <UserIcon icon={userIcon} width={20} height={20} color={palette.secondary.contrastText} />
                  <Box ml={.5} />
                  <FlexBox>
                    <OmitTypography
                      variant="subtitle2"
                      fontWeight={'400'}
                      sx={{ maxWidth: 128 }}
                    >
                      {currentComment.commentAuthor}
                    </OmitTypography>
                    <Box ml={.2} />
                    <Typography variant="subtitle2" fontWeight={'400'}>さん</Typography>
                  </FlexBox>
                </AlignStretch>
                <AlignCenter
                  width={.35}
                  justifyContent="flex-end"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (replay.length > 0) {
                      const find = isMoreComment.find((v) => v === currentComment.id);
                      if (find) {
                        onHandleClickReplay(currentComment.id, currentComment.commentAuthor);
                        if (elCommentBox) {
                          elCommentBox.scrollIntoView({
                            behavior: 'smooth'
                          });
                        }
                      } else {
                        setIsMoreComment([
                          ...isMoreComment,
                          currentComment.id
                        ]);
                      }
                    } else {
                      onHandleClickReplay(currentComment.id, currentComment.commentAuthor);
                      if (elCommentBox) {
                        elCommentBox.scrollIntoView({
                          behavior: 'smooth'
                        });
                      }
                    }
                  }}
                >
                  {isMoreComment.find((v) => v === currentComment.id) ? (
                    <>
                      {user && user.name && user.userId !== currentComment.userId && (
                        <Comment text={'返信する'} />
                      )}
                    </>
                  ) : (
                    <>
                      {replay.length > 0 ? (
                        <Comment text={`${replay.length}件の返信`} />
                      ) : (
                        <>
                          {user && user.name && user.userId !== currentComment.userId && (
                            <Comment text={'返信する'} />
                          )}
                        </>
                      )}
                    </>
                  )}
                </AlignCenter>
              </AlignCenter>
              {isMoreComment.find((v) => v === currentComment.id) && (
                <>
                  {replay.flatMap((item, idx) => {
                    return (
                      <AlignCenter
                        key={idx}
                        width={1}
                        justifyContent="flex-end"
                      >
                        <Box width={.9}>
                          <Box mt={2} />
                          <OmitTypography fontSize={14} color={palette.primary.light}>@{item.commentAuthor}</OmitTypography>
                          <Box mt={.5} />
                          <Typography fontSize={14}>{item.replay}</Typography>
                          <Box mt={1} />
                          <AlignCenter justifyContent="space-between">
                            <AlignStretch width={.8}>
                              <UserIcon icon={item.icon} width={20} height={20} color={palette.secondary.contrastText} />
                              <Box ml={.5} />
                              <FlexBox>
                                <OmitTypography
                                  variant="subtitle2"
                                  fontWeight={'400'}
                                  sx={{ maxWidth: 135 }}
                                >
                                  {item.replayAuthor}
                                </OmitTypography>
                                <Box ml={.2} />
                                <Typography variant="subtitle2" fontWeight={'400'}>さん</Typography>
                              </FlexBox>
                            </AlignStretch>
                            {user && user.name && user.userId !== item.userId && (
                              <AlignCenter
                                width={.35}
                                justifyContent="flex-end"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  onHandleClickReplay(item.replayId, item.replayAuthor);
                                  if (elCommentBox) {
                                    elCommentBox.scrollIntoView({
                                      behavior: 'smooth'
                                    });
                                  }
                                }}
                              >
                                <Comment text={'返信する'} />
                              </AlignCenter>
                            )}
                          </AlignCenter>
                        </Box>
                      </AlignCenter>
                    );
                  })}
                </>
              )}
            </Box>
          </Box>
        );
      })}
      {currentComments.length > 5 && !isMore && (
        <MoreInfoButton
          currentComments={currentComments}
          setCount={setCount}
          setIsMore={setIsMore}
        />
      )}
    </>
  )
}