import React from 'react';
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from '../../contexts/Auth';
import { FishProvider } from '../../contexts/Fish';
import { StateProvider } from '../../contexts/State';
import { CommentProvider } from '../../contexts/Comment';
import { ImageProvider } from '../../contexts/Image';
import { PostProvider } from '../../contexts/Post';
import { NewsProvider } from '../../contexts/News';

interface OwnProps {
  children: any;
}

export const Provider: React.FC<OwnProps> = (props) => {
  const { children } = props;
  return (
    <>
      <CookiesProvider>
        <AuthProvider>
          <StateProvider>
            <CommentProvider>
              <ImageProvider>
                <PostProvider>
                  <FishProvider>
                    <NewsProvider>
                      {children}
                    </NewsProvider>
                  </FishProvider>
                </PostProvider>
              </ImageProvider>
            </CommentProvider>
          </StateProvider>
        </AuthProvider>
      </CookiesProvider>
    </>
  )
}
