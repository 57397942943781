import { createContext } from 'react';
import axios from 'axios';

interface IPostContext {
  getPostStatus: any;
}

const PostContext = createContext<IPostContext>({
  getPostStatus: undefined
});

const PostProvider = (props: any) => {

  const getPostStatus = async (postId: number) => {
    const url = process.env.REACT_APP_URL;
    if (!url) return;
    const response = await axios({
      method: 'get',
      url: `${url}wp-json/custom/v2/maverick-get-post-status/${postId}`,
    });
    return response.data;
  };

  return (
    <PostContext.Provider
      value={{
        getPostStatus
      }}
    >
      {props.children}
    </PostContext.Provider>
  );
};

export { PostContext, PostProvider };