import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { Box } from '@mui/material';
import { CenterColumnBox, palette } from '../../theme';
import { TailSpin } from 'react-loader-spinner';

interface OwnProps {
  open: boolean;
}

export const BasicBackdrop: React.FC<OwnProps> = (props) => {
  const { open } = props;

  return (
    <Backdrop
      sx={{ background: palette.background.default }}
      open={open}
    >
      <CenterColumnBox>
        <Box>
          <TailSpin
            color={palette.secondary.light}
            height={120}
            width={120}
            radius={0}
          />
        </Box>
      </CenterColumnBox>
    </Backdrop>
  );
}
