import React, { createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { TContext } from '../models/TContext';
import { TBoat, TData, TFish } from '../models/TFish';
import { StateContext } from './State';

interface IFishContext {
	boats: TBoat[];
	fishes: TFish[];
	getBoat: any;
	getBoats: any;
	insertDb: any;
	getFishesData: any;
}

export const FishContext = createContext<IFishContext>({
	boats: [],
	fishes: [],
	getBoat: undefined,
	getBoats: undefined,
	insertDb: undefined,
	getFishesData: undefined
})

export const FishProvider: React.FC<TContext> = (props: any) => {
	const { setIsLoadingPosts } = useContext(StateContext);
	const [fishes, setFishes] = useState<TFish[]>([]);
	const [boats, setBoats] = useState<TBoat[]>([]);

	const getFishesData = async () => {
		const url = process.env.REACT_APP_URL;
		const imageUrl = process.env.REACT_APP_IMAGE_URL;
		if (!url || !imageUrl) return;
		const response = await axios({
			method: 'get',
			url: `${url}wp-json/custom/v2/maverick-fishes`,
		});
		let sorted: TFish[] = [];
		if (response) {
			const data = response.data;
			if (data.length > 0) setIsLoadingPosts(true);
			const fishList: TFish[] = [];
			for (let i = 0; i < data.length; i++) {
				const imagesList = data[i]['images'].split(',') as string[];
				if (data[i]['post_status'] && data[i]['post_status'] === 'publish' && Number(data[i]['is_show']) === 1) {
					fishList.push({
						id: Number(data[i]['ID']),
						maverickId: Number(data[i]['maverick_id']),
						postId: Number(data[i]['post_id']),
						area: data[i]['area'],
						boatId: Number(data[i]['boat_id']),
						boatName: data[i]['boat_name'],
						comment: data[i]['comment'],
						createdAt: dayjs(data[i]['created_at']),
						date: dayjs(data[i]['date']),
						images: imagesList,
						jig: data[i]['jig'],
						name: data[i]['name'],
						rank: Number(data[i]['rank']),
						previousRank: Number(data[i]['previous_rank']),
						size: Number(data[i]['size']),
						postStatus: data[i]['post_status'],
						updatedAt: dayjs(data[i]['updated_at']),
						userId: Number(data[i]['user_id']),
						isShow: Number(data[i]['is_show']),
						isRank: Number(data[i]['is_rank']),
					});
				}
			}
			if ([...fishList].length > 0 && [...fishList][0].isShow === 0) {
				sorted = [...fishList].sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf());
			} else {
				sorted = [...fishList].sort((a, b) => b.size - a.size);
				const first = sorted.filter((v) => v.isRank);
				const last = sorted.filter((v) => !v.isRank);
				sorted = [...first, ...last];
			}
			setFishes(sorted);
		}
		return sorted;
	};

	useEffect(() => {
		(async () => {
			await getFishesData();
		})();
		return;
		// eslint-disable-next-line
	}, []);

	const getBoat = async (derbyId: number, boatId: number) => {
		const url = process.env.REACT_APP_URL;
		if (!url) return;
		const response = await axios({
			method: 'get',
			url: `${url}wp-json/custom/v2/maverick-boat/${boatId}/${derbyId}`,
		});
		return response.data;
	};

	const getBoats = async (area: number) => {
		const url = process.env.REACT_APP_URL;
		if (!url) return;
		if (area) {
			const response = await axios({
				method: 'get',
				url: `${url}wp-json/custom/v2/maverick-boats/${area}`,
			});
			if (response.status === 200) {
				const data = response.data;
				const boatList: TBoat[] = [];
				for (let i = 0; i < data.length; i++) {
					boatList.push({
						id: Number(data[i]['id']),
						name: data[i]['name']
					});
				}
				setBoats([...boatList]);
				return [...boatList];
			}
			setBoats([]);
			return [];
		} else {
			setBoats([]);
			return [];
		}
	};

	const insertDb = async (data: TData, attachIds: number[], strAttachIds: string, attachId: number) => {
		const url = process.env.REACT_APP_URL;
		if (!url) return;
		const response = await axios({
			method: 'post',
			url: `${url}wp-json/custom/v2/maverick-post`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: {
				...data,
				attachIds: attachIds,
				strAttachIds: strAttachIds,
				attachId: attachId,
			}
		});
		if (response.status === 200) return response;
	};

	return (
		<FishContext.Provider
			value={{
				boats,
				fishes,
				getBoat,
				getBoats,
				insertDb,
				getFishesData
			}}
		>
			{props.children}
		</FishContext.Provider>
	)
}