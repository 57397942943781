import { Box, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Title } from '../atoms/Title';
import { CommentBox } from '../molecules/CommentBox';
import { PostDetail } from '../molecules/PostDetail';
import { useNavigateToTop } from '../../config';
import { AlignCenter, CustomButton, CustomImageBox, FlexBox, palette } from '../../theme';
import { FishContext } from '../../contexts/Fish';
import { TFish } from '../../models/TFish';
import { CommentContext } from '../../contexts/Comment';
import { TReplayCommentId } from '../../models/TComment';
import { BackButton } from '../atoms/BackButton';
import { SliderComponent } from '../molecules/SliderComponent';
import { AuthContext } from '../../contexts/Auth';
import { StateContext } from '../../contexts/State';

export const Detail: React.FC = () => {
  const { fishes } = useContext(FishContext);
  const { user } = useContext(AuthContext);
  const { currentComments, getPostComments, insertComment } = useContext(CommentContext);
  const { setIsComment } = useContext(StateContext);
  const [id, setId] = useState<number>(0);
  const [post, setPost] = useState<TFish>();
  const [images, setImages] = useState<string[]>([]);
  const [pathName, setPathName] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [replayComment, setReplayComment] = useState<TReplayCommentId | null>(null);
  const navigate = useNavigateToTop();
  const pathname = window.location.pathname;
  const search = window.location.search;

  const handleClick = async () => {
    if (!post) return;
    if (replayComment) {
      await insertComment(post.postId, comment, replayComment.id);
    } else {
      await insertComment(post.postId, comment, 0);
    }

    await getPostComments(post.postId);
    setComment('');
    setReplayComment(null);
    return;
  };

  const onHandleClickReplay = (id: number, commentAuthor: string) => {
    setReplayComment({
      id: id,
      author: commentAuthor
    });
  };

  useEffect(() => {
    setIsComment('');
    const list = pathname.split('/');
    const set = () => {
      const id = list[2];
      setId(Number(id));
    };
    if (list.length === 4) {
      const mIndex = search === '?path=m';
      const dIndex = search === '?path=d';
      if (mIndex) {
        set();
        setPathName('mypage');
        return;
      } else if (dIndex) {
        set();
        setPathName('derby');
        return;
      } else {
        navigate('/derby');
        return;
      }
    } else if (list.length !== 3) {
      navigate('/derby');
      return;
    }
    const id = list[2];
    setId(Number(id));
    setPathName('derby');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const value = fishes.find((v) => v.id === id);
      if (value) {
        setImages(value.images);
        setPost(value);
        await getPostComments(value.postId);
      }
    })();
    // eslint-disable-next-line
  }, [id, fishes]);

  return (
    <Box>
      {post && (
        <>
          <BackButton pathName={`/${pathName}`} />
          <Title />
          <Box mt={2} />
          {images.length > 1 ? (
            <SliderComponent images={images} />
          ) : (
            <Box height={200}>
              <CustomImageBox
                style={{
                  backgroundImage: `url(${images[0]})`,
                  backgroundSize: 'contain'
                }}
              />
            </Box>
          )}
          <Box mt={2} />
          <Box>
            <Typography textAlign="end" fontSize={12}>釣行日: {post.date.format('YYYY.MM.DD')}</Typography>
            <Box mt={2} />
            <PostDetail post={post} />
          </Box>
          <Box mt={3} />
          <Typography
            variant="subtitle2"
            fontWeight={'400'}
          >
            {post.comment}
          </Typography>
          <Box mt={user && user.name ? 5 : 10} />
          <>
            <Box>
              {user && user.name && (
                <AlignCenter
                  id="comment-box"
                  justifyContent="space-between"
                >
                  <FlexBox>
                    <Typography
                      variant="subtitle2"
                      sx={{ cursor: 'pointer' }}
                    >
                      {replayComment ? `${replayComment.author}へ返信する` : ''}
                    </Typography>
                    <Box ml={1} />
                    <Typography
                      variant="subtitle2"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setReplayComment(null);
                      }}
                    >
                      {replayComment ? `キャンセル` : ''}
                    </Typography>
                  </FlexBox>
                </AlignCenter>
              )}
              {user && user.name ? (
                <>
                  <Box mt={1} />
                  <TextField
                    disabled={user && user.name ? false : true}
                    multiline
                    rows={5}
                    value={comment}
                    placeholder="コメントを記入してください"
                    sx={{
                      background: palette.background.paper,
                      width: '100%'
                    }}
                    InputProps={{
                      style: {
                        borderRadius: 'unset',
                        fontSize: 16,
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setComment(value);
                    }}
                  />
                  <Box mt={2} />
                </>
              ) : (
                <Box mt={2} />
              )}
              {user && user.name ? (
                <FlexBox
                  width={1}
                  justifyContent="center"
                >
                  <CustomButton
                    sx={{ width: '50%' }}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    コメントを送信する
                  </CustomButton>
                </FlexBox>
              ) : (
                <FlexBox
                  width={1}
                  justifyContent="center"
                >
                  <CustomButton
                    sx={{ width: '70%' }}
                    onClick={() => {
                      navigate('/login');
                      setIsComment(`${id}/?path=d`);
                    }}
                  >
                    ログインしてコメントする
                  </CustomButton>
                </FlexBox>
              )}
              {currentComments.length > 0 && (
                <>
                  <Box mt={4} />
                  <Typography variant="subtitle2">{currentComments.length}件のコメント</Typography>
                  <Box mt={1} />
                  <CommentBox onHandleClickReplay={onHandleClickReplay} />
                </>
              )}
            </Box>
            <Box mt={8} />
          </>
        </>
      )
      }
    </Box >
  )
}