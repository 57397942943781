import axios from 'axios';
import dayjs from 'dayjs';
import { createContext, useContext, useState } from 'react';
import { AuthContext } from './Auth';
import { TComment, TReplayComment } from '../models/TComment';


interface ICommentContext {
  currentComments: TComment[];
  replayComments: TReplayComment[];
  insertComment: any;
  getPostComments: any;
}

const CommentContext = createContext<ICommentContext>({
  currentComments: [],
  replayComments: [],
  insertComment: undefined,
  getPostComments: undefined,
});

const CommentProvider = (props: any) => {
  const { user } = useContext(AuthContext);
  const [currentComments, setCurrentComments] = useState<TComment[]>([]);
  const [replayComments, setReplayComments] = useState<TReplayComment[]>([]);

  const getPostComments = async (postId: number) => {
    const url = process.env.REACT_APP_URL;
    if (!url || !postId) return;
    const response = await axios({
      method: 'get',
      url: `${url}wp-json/custom/v2/maverick-get-post-comments/${postId}`,
    });
    const data = response.data.req.data[0];
    const commentData = data['comments'];
    const commentList: TComment[] = [];
    for (let i = 0; i < commentData.length; i++) {
      commentList.push({
        id: Number(commentData[i]['comments']['comment_ID']),
        commentAuthor: commentData[i]['comments']['comment_author'],
        commentContent: commentData[i]['comments']['comment_content'],
        commentPostId: Number(commentData[i]['comments']['comment_post_ID']),
        commentParent: Number(commentData[i]['comments']['comment_parent']),
        userId: Number(commentData[i]['comments']['user_id']),
        icon: commentData[i]['icon'],
        commentDate: dayjs(commentData[i]['comment_date']),
      });
    }

    const list = commentList.flatMap((v) => {
      return {
        id: v.id,
        commentParent: v.commentParent,
        comment: v.commentContent,
        commentAuthor: v.commentAuthor,
        userId: v.userId,
        icon: v.icon,
        commentDate: v.commentDate
      };
    });

    const commentIds = commentList.flatMap((v) => {
      return {
        id: v.id,
        commentAuthor: v.commentAuthor
      };
    });

    const replayList: TReplayComment[] = [];
    for (let i = 0; i < commentIds.length; i++) {
      const filter = list.filter((v) => v.commentParent === commentIds[i].id);
      if (filter.length > 0) {
        for (let n = 0; n < filter.length; n++) {
          replayList.push({
            id: commentIds[i].id,
            icon: filter[n].icon,
            commentAuthor: commentIds[i].commentAuthor,
            commentDate: filter[n].commentDate,
            replayId: filter[n].id,
            replay: filter[n].comment,
            replayAuthor: filter[n].commentAuthor,
            userId: filter[n].userId
          });
        }
      }
    }
    const filterComments = commentList.filter((v) => v.commentParent === 0);
    setCurrentComments(filterComments);
    setReplayComments(replayList);
    return commentList;
  };

  const insertComment = async (
    postId: number,
    comment: string,
    replyId: number
  ) => {
    const url = process.env.REACT_APP_URL;
    if (!url) return;
    const formData = new FormData();
    formData.append('userId', user ? String(user.userId) : '0');
    formData.append('postId', String(postId));
    formData.append('comment', comment);
    formData.append('replyId', String(replyId));
    const response = await axios({
      method: 'post',
      url: `${url}wp-json/custom/v2/maverick-post-comment`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData
    });
    return response;
  };

  return (
    <CommentContext.Provider
      value={{
        currentComments,
        replayComments,
        insertComment,
        getPostComments
      }}
    >
      {props.children}
    </CommentContext.Provider>
  );
};

export { CommentContext, CommentProvider };