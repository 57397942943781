import { createContext, useContext } from 'react';
import axios from 'axios';
import { TImage } from '../models/TImage';
import { AuthContext } from './Auth';

interface IImageContext {
  uploadImage: any;
}

const ImageContext = createContext<IImageContext>({
  uploadImage: undefined
});

const ImageProvider = (props: any) => {
  const { user } = useContext(AuthContext);

  const uploadImage = async (files: FileList) => {
    const url = process.env.REACT_APP_URL;
    if (!url) return;
    if (files.length === 0) return;
    const fileList = Array.from(files);
    const uploadImages: TImage[] = [];
    for (let v of fileList) {
      const formData = new FormData();
      formData.append('file', v);
      formData.append('userId', user ? String(user.userId) : '0');
      const response = await axios({
        method: 'post',
        url: `${url}wp-json/custom/v2/maverick-upload-image`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData
      });
      const imageUrl = {
        url: String(response.data.req.data.moveFile.url),
        attachId: Number(response.data.req.data.attachId),
        attachFile: response.data.req.data.attachData
      };
      uploadImages.push(imageUrl);
    }
    return uploadImages;
  };

  return (
    <ImageContext.Provider
      value={{
        uploadImage
      }}
    >
      {props.children}
    </ImageContext.Provider>
  );
};

export { ImageContext, ImageProvider };